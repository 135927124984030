import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./index.css"
import Hero from "./Components/Hero/Hero";
import Footer from "./Components/Navbar/Footer";
import Navbar from "./Components/Navbar/Nabar";
import About from "./Components/Pages/About";
import Blog from "./Components/Pages/Blog";
import Contact from "./Components/Pages/Contact";
import Pages from "./Components/Pages/Pages";
import Service from "./Components/Pages/Service";
import Navbartwo from "./Components/Navbar/Navbartwo";
import WhyUs from "./Components/AboutUs/WhyUs";
import PricePackage from "./Components/AboutUs/PricePackage";
import OurTeam from "./Components/AboutUs/OurTeam";
import OurPortfolio from "./Components/AboutUs/OurPortfolio";
import Mission from "./Components/AboutUs/Mission";
import Business from "./Components/ServicePage/Business";
import Corporate from "./Components/ServicePage/Corporate";
import Ecommerce from "./Components/ServicePage/Ecommerce";
import WebApplication from "./Components/ServicePage/WebApplication";
import CMS from "./Components/ServicePage/CMS";
import WebDesign from "./Components/ServicePage/WebDesign";
import Mobile from "./Components/ServicePage/Mobile";
import IOS from "./Components/ServicePage/IOS";
import Hybrid from "./Components/ServicePage/Hybrid";
import Quality from "./Components/ServicePage/Quality";
import Android from "./Components/ServicePage/Android";
import MobileApp from "./Components/ServicePage/MobileApp";
import Marketing from "./Components/ServicePage/Marketing";
import Seo from "./Components/ServicePage/Seo";
import PPC from "./Components/ServicePage/PPC";
import Social from "./Components/ServicePage/Social";
import Local from "./Components/ServicePage/Local";
import Content from "./Components/ServicePage/Content";
import Branding from "./Components/ServicePage/Branding";
import Identity from "./Components/ServicePage/Identity";
import Brochure from "./Components/ServicePage/Brochure";
import Logo from "./Components/ServicePage/Logo";
import Animated from "./Components/ServicePage/Animated";
import Creative from "./Components/ServicePage/Creative";
import PHP from "./Components/TechnologiesPage/PHP";
import Python from "./Components/TechnologiesPage/Python";
import Asp from "./Components/TechnologiesPage/Asp";
import Angular from "./Components/TechnologiesPage/Angular";
import Reactjs from "./Components/TechnologiesPage/Reactjs";
import Shopify from "./Components/TechnologiesPage/Shopify";
import Swift from "./Components/TechnologiesPage/Swift";
import Native from "./Components/TechnologiesPage/Native";
import Flutter from "./Components/TechnologiesPage/Flutter";
import Ionic from "./Components/TechnologiesPage/Ionic";
import Java from "./Components/TechnologiesPage/Java";
import Unity from "./Components/TechnologiesPage/Unity";
import Numpy from "./Components/TechnologiesPage/Numpy";
import Ruby from "./Components/TechnologiesPage/Ruby";
import Julia from "./Components/TechnologiesPage/Julia";
import Lisp from "./Components/TechnologiesPage/Lisp";
import Haskell from "./Components/TechnologiesPage/Haskell";
import Cblock from "./Components/TechnologiesPage/Cblock";
import Customer from "./Components/TechnologiesPage/Customer";
import Solidity from "./Components/TechnologiesPage/Solidity";
import PythonBlockchain from "./Components/TechnologiesPage/PythonBlockchain";
import Faq from "./Components/Pages/Faq";
import Refund from "./Components/Pages/Refund";
import Privacy from "./Components/Pages/Privacy";
import TermsCondition from "./Components/Pages/TermsCondition";
import SignIn from "./Components/Forms/SignIn";
import Login from "./Components/Forms/Login";
import Sendotp from "./Components/Pages/Sendotp";


import BlogView from "./Components/Pages/BlogView";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Navbartwo />

      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/trending-technology" element={<Pages />} />
        <Route path="/service" element={<Service />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/price" element={<PricePackage />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/our-portfolio" element={<OurPortfolio />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/Privacy-Policy" element={<Privacy />} />
        <Route path="/Terms-Conditions" element={<TermsCondition />} />
        <Route path="/sign" element={<SignIn />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sendotp" element={<Sendotp/>} />
        
        <Route path="/blog/:slug" element={<BlogView />} />



        <Route path="/business-developement" element={<Business />} />
        <Route path="/web-app-developemnt-corporate" element={<Corporate />} />
        <Route path="/ecommerce-website-development" element={<Ecommerce />} />
        <Route path="/web-app-development" element={<WebApplication />} />
        <Route path="/website-design" element={<WebDesign />} />
        <Route path="/cms-development" element={<CMS />} />


        <Route path="/mobile-application-testing" element={<Mobile />} />
        <Route path="/ios-application-development" element={<IOS />} />
        <Route path="/hybrid-application-development" element={<Hybrid />} />
        <Route path="/quality-assurance" element={<Quality />} />
        <Route path="/android-application-development" element={<Android />} />
        <Route path="/application-developement" element={<MobileApp />} />

        <Route path="/digital-marketing-service" element={<Marketing />} />
        <Route path="/search-engine-optimization" element={<Seo />} />
        <Route path="/pay-per-click-service" element={<PPC />} />
        <Route path="/social-media-marketing-service" element={<Social />} />
        <Route path="/local-search-engine-optimization" element={<Local />} />
        <Route path="/content-marketing-service" element={<Content />} />

        <Route path="/graphic-design-services" element={<Branding />} />
        <Route path="/logo-design-services" element={<Logo />} />
        <Route path="/corporate-stationery-design" element={<Identity />} />
        <Route path="/brochure-design-service" element={<Brochure />} />
        <Route path="/animated-services" element={<Animated />} />
        <Route path="/creative-services" element={<Creative />} />

        <Route path="/php-laravel-codeigniter-services" element={<PHP />} />
        <Route path="/python-and-django-service" element={<Python />} />
        <Route path="/asp-dot-net-service" element={<Asp />} />
        <Route path="/angularjs-development-service" element={<Angular />} />
        <Route path="/reactjs-vuejs-nodejs-development-services" element={<Reactjs />} />
        <Route path="/wordpress-and-shopify-development" element={<Shopify />} />

        <Route path="/swift-app-development" element={<Swift />} />
        <Route path="/react-native-application-development" element={<Native />} />
        <Route path="/flutter-application-development" element={<Flutter />} />
        <Route path="/ionic-application-development" element={<Ionic />} />
        <Route path="/java-application-development" element={<Java />} />
        <Route path="/unity-game-development" element={<Unity />} />

        <Route path="/python-numpy-development" element={<Numpy />} />
        <Route path="/ruby-and-pybrain-development" element={<Ruby />} />
        <Route path="/julia-developement-service" element={<Julia />} />
        <Route path="/lisp-online-store-development" element={<Lisp />} />
        <Route path="/haskell-ai-and-ml-development" element={<Haskell />} />

        <Route path="/c-plus-plus-blockchain-development" element={<Cblock />} />
        <Route path="/customer-polls-blockchain" element={<Customer />} />
        <Route path="/solidity-blockchain-development" element={<Solidity />} />
        <Route path="/python-blockchain-development" element={<PythonBlockchain />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
