import React, {useState} from "react";
import breadcrumb7 from "../Assets/breadcrumb-shape-7.webp";
import breadcrumb1 from "../Assets/breadcrumb-shape-1.webp";
import breadcrumb2 from "../Assets/breadcrumb-shape-2.webp";
import breadcrumb6 from "../Assets/breadcrumb-shape-6.webp";
import breadcrumb5 from "../Assets/breadcrumb-shape-5.webp";
import { Link } from "react-router-dom";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";

const Refund = () => {
    const faqs = [
        {
            question: "Refund and Cancellation Policy",
            answer: "Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will refund back the money, provided the reasons are genuine and proved after investigation. Project can only be cancelled within 3 days after signing the project agreement. If there is a refund, client will get refund within 10 days of the claim Please read the fine prints of each deal before buying it, it provides all the details about the services or the product you purchase.",
            li: "In case of dissatisfaction from our services, clients have the liberty to cancel their projects and request a refund from us. Our Policy for the cancellation and refund will be as follows:",
        },
        
        {
            question: "Cancellation Policy",
            answer: "For Cancellations please contact the us via contact us link. ",
            li: "Requests received later than 3 business days prior to the end of the current service period will be treated as cancellation of services for the next service period.",
          
      
        },
        
        {
            question: "Refund Policy",
            answer: "We will try our best to create the suitable design concepts for our clients.",
            li: "In case any client is not completely satisfied with our products we can provide a refund. ",
            liTwo: "If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of payment gateway name payments refund will be made to the same account.",
        },
      
    ];

    
    
    
      const [activeIndex, setActiveIndex] = useState(null);
    
      const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
      };
    
  return (
    <>
        <div className="breadcrumb-wrapper relative overflow-hidden bg-no-repeat h-60 sm:h-60 md:h-72 lg:h-96">
        <div className="">
          <div className="absolute z-10 top-28 sm:top-28 md:top-28 lg:top-48 left-10 sm:left-10 md:left-28 lg:left-40">
            <div className="">
              <h1 className="text-white font-bold text-3xl sm:text-3xl md:text-4xl lg:text-6xl">Refund Policy</h1>
            </div>
            <div className="text-white font-bold ml-2 mt-2 max-sm:ml-1 sm:mt-2 md:mt-4 lg:mt-6 text-base">
              <span className="mr-1">
                <Link to="https://webency.themejunction.net">Home</Link>
              </span>
              <span className="mr-1"> &gt;</span>
              <span className="">Refund Policy</span>
            </div>
          </div>
        </div>

        <div className="relative px-20">
          <div className="breadcrumb-shape-3 opacity-30 top-20 absolute">
            <img src={breadcrumb1} alt="" />
          </div>
          <div className="breadcrumb-shape-2 shake-y absolute top-10 opacity-40 left-20">
            <img src={breadcrumb2} alt="" />
          </div>
          <div className="breadcrumb-shape-5 pulse absolute opacity-40 right-1/4">
            <img src={breadcrumb5} alt="" />
          </div>
          <div className="breadcrumb-shape-4 shake-y absolute opacity-40 top-52 left-2/4">
            <img src={breadcrumb2} alt="" />
          </div>
          <div className="breadcrumb-shape-1 pulse absolute opacity-40 top-52 left-2/4">
            <img src={breadcrumb1} alt="" />
          </div>
          <div className="breadcrumb-shape-6 pulse absolute opacity-40 top-52 right-40">
            <img src={breadcrumb6} alt="" />
          </div>
          <div className="breadcrumb-shape-7 shake-y absolute opacity-40 top-0 right-20">
            <img src={breadcrumb7} alt="" />
          </div>
          <div className="breadcrumb-shape-8 pulse absolute opacity-40 top-0 right-20">
            <img src={breadcrumb1} alt="" />
          </div>
        </div>
      </div>



      <div className="max-w-full mx-auto px-32 max-sm:px-5 py-14 pt-5 text-justify text-lg text-[#6a6a8e]">
        {faqs.map((faq, index) => (
       
          <div key={index} className="border border-[#d4f0ff] rounded-lg px-4 my-5 py-3">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleAnswer(index)}
            >
              <h3 className="text-lg font-medium">{faq.question}</h3>
              <span className="text-2xl font-bold text-[#00baff]">
                {activeIndex === index ? (<FaMinusCircle />) : (<FaPlusCircle />)}
              </span>
            </div>
            <div
              className={`overflow-hidden transition-max-height ease-in-out duration-1000 ${
                activeIndex === index ? 'max-h-screen' : 'max-h-0'
              }`}
            >
              <p className="mt-3">{faq.answer} <br /> {faq.li} <br /> {faq.liTwo}  </p>
            </div>
          </div>
        ))}
      </div>

   
             
    </>
  )
}

export default Refund
