import React,{useEffect} from "react";
import breadcrumb7 from "../Assets/breadcrumb-shape-7.webp";
import breadcrumb1 from "../Assets/breadcrumb-shape-1.webp";
import breadcrumb2 from "../Assets/breadcrumb-shape-2.webp";
import breadcrumb6 from "../Assets/breadcrumb-shape-6.webp";
import breadcrumb5 from "../Assets/breadcrumb-shape-5.webp";
import blog1 from "../Assets/blog-dg-1.webp";
import blog2 from "../Assets/blog-dg-2.webp";
import blog3 from "../Assets/blog-dg-3.webp";
import { Link } from "react-router-dom";
import { FaClock, FaEnvelope, FaFacebook, FaFacebookMessenger, FaLinkedin, FaTag, FaTwitter } from "react-icons/fa";

const Blog = () => {
  useEffect(() => {
    document.title = "Blog - Future IT Touch Private Limited";
    window.scrollTo(0, 0);
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Future IT Touch Private Limited is a leading IT solutions provider.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "IT, Technology, Solutions, Future IT Touch";
    document.head.appendChild(metaKeywords);

    // Cleanup function to remove the meta tags when the component unmounts
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  const blogData = [
    {
      title: "Everything You Need To Know About Nodejs!",
      category: "Nodejs",
      date: "Sep 23, 2020",
      content:
        "Node.js is a fast, scalable runtime for server-side JavaScript, ideal for real-time applications, built on Chrome's V8 engine.",
      image: blog1,
      bgColor: "dg-bg2",
      slug: 'everything-you-need-to-know-about-nodejs'
    },
    {
      title: "Exploring the Key Features of Laravel 7 Framework",
      category: "Laravel",
      date: "Sep 24, 2020",
      content:
        "Laravel 7 offers elegant syntax, robust routing, authentication, scheduling, real-time broadcasting, and seamless integration.",
      image: blog2,
      bgColor: "bg-gradient12",
      slug: 'exploring-the-key-features-of-laravel-7-framework'
    },
    {
      title: "Best Technology for Mobile Application Development",
      category: "Mobile Application",
      date: "Sep 25, 2020",
      content:
        "React Native, Flutter, Swift, Kotlin, Xamarin, and Ionic excel in mobile app development with unique benefits.",
      image: blog3,
      slug: 'best-technology-for-mobile-application-development',
      bgColor: "dg-bg2",
    },
   
   
  ];

  return (
    <>
      <div className="breadcrumb-wrapper relative -z-10  overflow-hidden bg-no-repeat h-60 sm:h-60 md:h-72 lg:h-96">
        <div className="">
          <div className="absolute z-10 top-28 sm:top-28 md:top-28 lg:top-48 left-10 sm:left-10 md:left-28 lg:left-40">
            <div className="">
              <h1 className="text-white font-bold text-3xl sm:text-3xl md:text-4xl lg:text-6xl">Our Blogs</h1>
            </div>
            <div className="text-white font-bold ml-2 mt-2 max-sm:ml-1 sm:mt-2 md:mt-4 lg:mt-6 text-base">
              <span className="mr-1">
                <Link to="/">Home</Link>
              </span>
              <span className="mr-1"> &gt;</span>
              <span className=""> Blogs</span>
            </div>
          </div>
        </div>

        <div className="relative px-20">
          <div className="breadcrumb-shape-3 opacity-30 top-20 absolute">
            <img src={breadcrumb1} alt="" />
          </div>
          <div className="breadcrumb-shape-2 shake-y absolute top-10 opacity-40 left-20">
            <img src={breadcrumb2} alt="" />
          </div>
          <div className="breadcrumb-shape-5 pulse absolute opacity-40 right-1/4">
            <img src={breadcrumb5} alt="" />
          </div>
          <div className="breadcrumb-shape-4 shake-y absolute opacity-40 top-52 left-2/4">
            <img src={breadcrumb2} alt="" />
          </div>
          <div className="breadcrumb-shape-1 pulse absolute opacity-40 top-52 left-2/4">
            <img src={breadcrumb1} alt="" />
          </div>
          <div className="breadcrumb-shape-6 pulse absolute opacity-40 top-52 right-40">
            <img src={breadcrumb6} alt="" />
          </div>
          <div className="breadcrumb-shape-7 shake-y absolute opacity-40 top-0 right-20">
            <img src={breadcrumb7} alt="" />
          </div>
          <div className="breadcrumb-shape-8 pulse absolute opacity-40 top-0 right-20">
            <img src={breadcrumb1} alt="" />
          </div>
        </div>
      </div>
      <div className=" mx-auto py-20 px-44 max-lg:px-10 max-md:px-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogData.map((blog, index) => (
          <div key={index} className="blog-item  rounded-lg shadow-lg">


            <Link to={blog.slug} >
            <img
              className="w-full h-48 object-cover rounded-t-lg"
              src={blog.image}
              alt=""
            />
            </Link>
            
            <div className="p-8">
              <div
                className={`flex justify-between items-center ${blog.bgColor} -top-14  relative text-white py-3 rounded-3xl px-3`}
              >
                <p className=" text-sm  flex items-center gap-2 ">
                  <FaTag />
                  {blog.category}
                </p>
                <p className=" text-sm flex items-center gap-2">
                  <FaClock /> {blog.date}
                </p>
              </div>
              <Link to={blog.slug} >
              <h2 className="text-xl font-bold -mt-8 mb-3">{blog.title}</h2>
              </Link>
             
              <p className="text-gray-800 border-b-2 pb-5">{blog.content}</p>
              <div className="flex mt-5 text-[#6a6a8e] items-center gap-3">
    <Link to="" className=" text-lg">Shares</Link>
    <Link to="" target="_blank" rel="noopener noreferrer">
      <FaFacebook  />
    </Link>
    <Link to="" target="_blank" rel="noopener noreferrer">
      <FaTwitter />
    </Link>
    <Link to="" target="_blank" rel="noopener noreferrer">
      <FaLinkedin />
    </Link>
    <Link to="" target="_blank" rel="noopener noreferrer">
      <FaEnvelope />
    </Link>
    <Link to="" target="_blank" rel="noopener noreferrer">
      <FaFacebookMessenger />
    </Link>
  </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Blog;
