import React, {useEffect} from "react";
import ec  from "../Assets/iii.gif";
import phone from "../Assets/ec.webp";
import io from "../Assets/io.webp";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { iosAndroid, ecommerceData } from "../common/Service_comp";
import { rangeIos } from "../common/Service_comp";

const IOS = () => {
  useEffect(() => {
    document.title = "Best IOS App Development Services in India | Future IT Touch";
    window.scrollTo(0, 0);
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Future IT Touch  provides end-to-end iOS app development services for iPhone, iPad, Apple Watch, Apple TV and other Apple mobile devices.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "IT, Technology, Solutions, Future IT Touch";
    document.head.appendChild(metaKeywords);

    // Cleanup function to remove the meta tags when the component unmounts
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);
  return (
    <>
       <div className="h-24 max-sm:h-[57px] max-md:h-20 max-lg:h-24 bg-white"></div>
      <div className=" relative overflow-hidden bg-[#442a79] h-[400px] max-sm:h-auto  max-lg:h-auto">
      <div className="flex max-sm:block max-lg:block px-28  max-sm:px-5 max-lg:px-10  max-sm:py-7 gap-10  pt-12 max-pt-1  max-lg:px-10  max-lg:py-5">
          <div className="  ">
            <h1 className="text-white font-bold text-4xl  max-sm:text-2xl max-lg:text-xl">
            IOS APP DEVELOPMENT SERVICES
            </h1>

            <div className="text-[#dcd7f8]  mt-3 text-lg text-justify max-lg:text-base">
              <p>
              Future IT Touch Pvt. Ltd. provides cutting-edge mobile app development services to turn your dreams of a business app into reality. We have a proficient team for custom iOS app development, well versed in the latest frameworks, Objective C and Swift. We give our best efforts into creating highly interactive, innovative and scalable apps that meet your business objectives. By far, we have developed about 150+ mobile apps for a worldwide client base - from start-ups to SMBs and large enterprises. Engage our iOS app development services to craft dynamic, feature-rich, and industry-standard mobile applications that seamlessly run on the latest versions of iOS.
              </p>
            </div>
            <div className="flex gap-3 mt-5">
              <Link to="/contact" className="flex items-center bg-[#dc3545] justify-center bg-red text-white py-2 px-4 sm:px-8 font-heading transition duration-400 ease-in-out rounded-md text-base font-base focus:outline-none hover:bg-gray-300 hover:bg-gradient-to-r from-teal-400 to-indigo-700">
                Quick Enquiry
              </Link>
              <button className="flex items-center bg-dark-200 justify-center border text-white py-2 px-2  sm:px-8 font-heading ">
              <Link to="tel:+917056937000">

Call us - 7056937000

</Link>
              </button>
            </div>
          </div>
          <img src={io} alt="" className="w-96 pt-0  max-lg:pt-10 max-lg:mx-auto" />
        </div>
      </div>

      
      <div className=" px-32  max-sm:px-5 max-lg:px-10 py-14">
        <h3 className="text-[27px] text-[#050748] font-bold">
        Our Range of iPhone App Development Services
        </h3>
        <p className=" text-lg text-[#6a6a8e] mt-5">
        We offer an end-to-end iOS app solution to a global clientele. Take a look at our wide array of iPhone app development services:
        </p>
        <div className="flex max-lg:block gap-10 mt-10 max-sm:mt-2">
        
          <div className=" grid grid-cols-1 md:grid-cols-2 pt-10 gap-10  text-lg text-[#6a6a8e]">
            {iosAndroid.map((item, index) => (
               <div key={index}>
               <div className="flex items-center gap-5">
               
                  <item.icon className={`text-4xl text-[${item.color}]`} />
                
                  <p className={`text-xl font-bold text-[${item.color}]`}>
                    
               
                    {item.title}
                  </p>
                  </div>
                  <p className="mt-4 text-justify">{item.description}</p>
            </div>
                
            ))}
          </div>
          <div className="w-full mt-10">
            <img src={ec} alt="" className="  max-lg:mx-auto" />
          </div>
        </div>
        
      </div>
      
      <div className="bg-image-third mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row px-40  max-sm:px-5 max-lg:px-10">
          <div className="md:w-1/2"></div>

          <div className="md:w-1/2 md:ml-4">
            <div className="">
              <h3 className="text-3xl font-bold mb-4 text-[#050748]">
                WHAT CAN WE DO FOR YOU?
              </h3>
              <p className="mb-4">
              We have the expertise in developing top of the line eCommerce websites as well as mobile apps. Please let us know your requirement and we would be happy to discuss the projects on developing ecommerce websites or mobile apps with you.
              </p>
              <div className="flex items-center mb-4">
                <span className="mr-2">Call us on :</span>
                <p className="text-lg">+91 7056937000</p>
              </div>
              <div>
                <Link to="/our-portfolio">
                  <button className="bg-[#00a2ff] hover:bg-[#3b61e8] text-white font-semibold py-2 px-4 rounded-md mr-4">
                    RECENT PROJECTS
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#f2f2f2]  px-32  max-sm:px-5 max-lg:px-10 py-14">
        <h3 className="text-3xl max-sm:text-2xl max-md:text-3xl  text-[#050748] font-bold">
        Advantage of Partnering with a Top-Rated iOS App Development Company in India
        </h3>
       
        <div className="flex gap-10 mt-5">
          <div className=" grid grid-cols-1 md:grid-cols-2 pt-10 gap-10  text-lg text-[#6a6a8e]">
            {rangeIos.map((item, index) => (
               <div key={index}>
               <div className="flex items-center gap-5">
               
                  <item.icon className={`text-4xl text-[${item.color}]`} />
                
                  <p className={`text-xl font-bold text-[${item.color}]`}>
                    
               
                    {item.title}
                  </p>
                  </div>
                  <p className="mt-4 text-justify">{item.description}</p>
            </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-image-second  mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-5">
          <div className="md:w-1/2 max-sm:hidden"></div>

          <div className="md:w-1/2 md:ml-4">
            <div class="flex gap-5 items-center ">
              <div class="text-white  ">
                <h5 className="text-lg font-semibold"> GET SUPPORT FOR</h5>
                <h3 className="text-5xl max-sm:text-3xl max-md:text-3xl  font-bold">90 DAYS</h3>
              </div>

              <div>
                <Link to="/our-portfolio">
                  <button className="bg-[#00a2ff] hover:bg-[#3b61e8] text-white text-nowrap font-semibold py-4  px-6 max-sm:px-3 rounded-md mr-4">
                    REQUEST A QUOTE
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#f2f2f2] px-32  max-sm:px-5 max-lg:px-10 py-14">
        <h3 className="text-[27px] text-[#050748] font-bold">
        Our iPhone App Development Process
        </h3>
        <p className=" text-lg text-[#6a6a8e]">
        As a trusted eCommerce website design company in India, we guarantee you of the following:
        </p>
        <div className="flex max-sm:block gap-10 mt-10">
          <div className=" grid grid-cols-1 md:grid-cols-2 pt-10 gap-10  text-lg text-[#6a6a8e]">
            {ecommerceData.map((item, index) => (
               <div key={index}>
               <div className="flex items-center gap-5">
               
                  <item.icon className={`text-4xl text-[${item.color}]`} />
                
                  <p className={`text-xl font-bold text-[${item.color}]`}>
                    
               
                    {item.title}
                  </p>
                  </div>
                  <p className="mt-4 text-justify">{item.description}</p>
            </div>
            ))}
          </div>
          <div className="w-full mt-10">
            <img src={phone} alt="" className="border-[18px] border-[#dfdfdf]" />
          </div>
        </div>
        <p className=" text-lg text-[#6a6a8e] mt-10 text-justify">
        In the last 15 years, we have worked with hundreds of online retailers and delivered their customers with a beautiful online shopping experience. From startup to enterprise, single vendor to multi vendor, B2C to B2B business - we have provided effective eCommerce solutions to a global clientele. We have also built several eCommerce mobile apps as per the need of the businesses. <br />Further, we can promote the online stores through effective digital marketing. SEO, SMM, online reputation management, content & email marketing - we do it all. Rely on us and we will offer the best digital solutions for your eCommerce business.
        </p>
      </div>
      <div className="bg-custome py-10 px-4 sm:px-4 md:px-10 lg:px-20 grid grid-cols-1 md:grid-cols-12 gap-4">
        <div className="md:col-span-6">
          <h1 className="text-[#050748] text-3xl font-bold">
            Get New Insights Weekly
          </h1>
          <p className="mt-4">
            Your Personal data is secure as per our policy we dont share your
            personal information, <br /> Freely Subscribe !
          </p>
        </div>
        <div className="new-component relative md:col-span-6 w-full sm:w-full md:w-full lg:w-11/12 left-0 sm:left-0 md:left-1 lg:left-10 top-5">
          <form className="flex items-center justify-center">
            <input
              type="email"
              placeholder="Email Your Address"
              name="emails"
              className="w-full h-16 px-8 text-base text-gray-700 placeholder-gray-500 bg-white border border-gray-300 rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
            <button
              type="submit"
              name="submit"
              className="inline-flex items-center absolute top-0 right-0 mr-1 mt-1 px-4 py-2 text-base font-medium rounded-full text-white bg-gradient-to-r from-[#ffbd84] to-[#ff1f8e] shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              style={{
                borderRadius: "28px 100px 100px 28px",
                lineHeight: "40px",
              }}
            >
              Subscribe <FaChevronRight className="ml-2" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default IOS;
