import React, { useEffect, useRef } from 'react';
import Matter from 'matter-js';

const Heromatterjs = () => {
  const sceneRef = useRef(null);

  useEffect(() => {
    const setupMatterJS = () => {
      const Engine = Matter.Engine,
        Render = Matter.Render,
        World = Matter.World,
        Bodies = Matter.Bodies,
        Mouse = Matter.Mouse,
        MouseConstraint = Matter.MouseConstraint;

      const engine = Engine.create();
      const render = Render.create({
        element: sceneRef.current,
        engine: engine,
        options: {
          width: window.innerWidth - 100,
          height: window.innerWidth <= 768 ? 300 : 360,
          background: 'transparent',
          wireframes: false,
          pixelRatio: window.devicePixelRatio,
        },
      });

      if (!sceneRef.current) return;

      // Add CSS class to change the cursor
      render.canvas.classList.add('interactive-canvas');

      const ground = Bodies.rectangle(render.options.width / 2, render.options.height, render.options.width, 30, {
        isStatic: true,
        render: { fillStyle: 'transparent', strokeStyle: 'transparent' },
      });
      const leftWall = Bodies.rectangle(0, render.options.height / 2, 30, render.options.height, {
        isStatic: true,
        render: { fillStyle: 'transparent', strokeStyle: 'transparent' },
      });
      const rightWall = Bodies.rectangle(render.options.width, render.options.height / 2, 30, render.options.height, {
        isStatic: true,
        render: { fillStyle: 'transparent', strokeStyle: 'transparent' },
      });

      World.add(engine.world, [ground, leftWall, rightWall]);

      const colors = ['#ffd1dc', '#d1ffd1', '#d1d1ff', '#ffecd1', '#ffd1ec'];

      const mouse = Mouse.create(render.canvas);
      const mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: { render: { visible: false } },
      });

      World.add(engine.world, mouseConstraint);
      render.mouse = mouse;

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const textBodies = [
              'Machine Learning',
              'React.js Development',
              'PHP Laravel',
              'Digital Marketing',
              'Shopify Development',
              'E-commerce Development',
              'Node Js Development',
              'Artificial Intelligence',
              'Backend Development',
              'Frontend Development',
              'Data Science',
              'Web Development',
              'Mobile App Development',
      
              'DevOps',
            ].map((text, i) => {
              const positionX = render.options.width / 2 + (Math.random() - 0.5) * 500;
              return Bodies.rectangle(positionX, -100 * i, 350, 50, {
                chamfer: { radius: 25 },
                render: {
                  fillStyle: 'transparent',
                  strokeStyle: '#32ccaa',
                  lineWidth: 2,
                  text: { content: text, size: 18, color: '#5f45f9', fontWeight: 'bold' },
                },
                velocity: { x: (Math.random() - 0.5) * 4, y: 4 * Math.random() },
              });
            });

            window.addEventListener('resize', handleResize);

            World.add(engine.world, textBodies);
            render.context.font = 'bold 18px Inter, sans-serif';
            render.context.textAlign = 'center';
            render.context.textBaseline = 'middle';

            Matter.Events.on(render, 'afterRender', () => {
              textBodies.forEach((body) => {
                const { x, y } = body.position;
                const angle = body.angle;
                const { content, size, color } = body.render.text;
                render.context.font = `bold ${size}px Inter, sans-serif`;
                render.context.save();
                render.context.translate(x, y);
                render.context.rotate(angle);

                // Create the gradient
                const gradient = render.context.createLinearGradient(-175, 0, 175, 0);

                render.context.fillStyle = color;
                render.context.strokeStyle = gradient; // Apply gradient to stroke
                render.context.lineWidth = 1;
                render.context.fillText(content, 0, 0);
                render.context.strokeText(content, 0, 0); // Stroke the text with gradient
                render.context.restore();
              });
            });

            observer.unobserve(entry.target);
          }
        });
      }, { threshold: 0.1 });

      observer.observe(render.canvas);
      if (mouseConstraint.mouse.element) {
        mouseConstraint.mouse.element.removeEventListener('mousewheel', mouseConstraint.mouse.mousewheel);
        mouseConstraint.mouse.element.removeEventListener('DOMMouseScroll', mouseConstraint.mouse.mousewheel);
      }

      Engine.run(engine);
      Render.run(render);

      const handleResize = () => {
        Render.stop(render);
        World.clear(engine.world, true);
        Engine.clear(engine);
        render.canvas?.remove();
        render.canvas = null;
        render.context = null;
        render.textures = {};

        setupMatterJS();
      };

      return () => {
        window.removeEventListener('resize', handleResize);
        World.clear(engine.world, true);
        Engine.clear(engine);
        Render.stop(render);
        if (render.canvas) {
          render.canvas.remove();
          render.canvas = null;
          render.context = null;
          render.textures = {};
        }
      };
    };

    setupMatterJS();
  }, []);



  return (
    <div className='mt-5 px-10 max-md:hidden choose-background '>

      <div className='content text-center'>

        <h3 className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl mt-5 mb-3 font-bold my-5">
          <span
            className=" text-4xl mb-5 bg-gradient-to-r from-teal-400 to-indigo-700 text-transparent bg-clip-text tracking-widest "
            style={{ fontFamily: "'Bilbo Swash Caps', cursive" }}
          >
            Grow Your Business <br />
          </span>
          With Our
          <span className="bg-gradient-to-r from-teal-400 to-indigo-700 text-transparent leading-normal bg-clip-text ml-2">
            Expertise
          </span>
        </h3>

      </div>

      <div className="service-matter px-4 py-4" ref={sceneRef}></div>

    </div>
  );
};

export default Heromatterjs;



// Heromatterjs
