import {
  Fa500Px,
  FaAccusoft,
  FaAddressBook,
  FaAsymmetrik,
  FaAtlas,
  FaAutoprefixer,
  FaBattleNet,
  FaBlenderPhone,
  FaBook,
  FaBookDead,
  FaBookMedical,
  FaBookOpen,
  FaBookReader,
  FaBookmark,
  FaBullhorn,
  FaCalendarAlt,
  FaCartPlus,
  FaCashRegister,
  FaCheckCircle,
  FaClock,
  FaCloudSunRain,
  FaCog,
  FaCogs,
  FaComments,
  FaCompass,
  FaDatabase,
  FaDollarSign,
  FaDraftingCompass,
  FaEdit,
  FaEnvelope,
  FaEnvelopeOpen,
  FaFileAlt,
  FaFileAudio,
  FaFileInvoiceDollar,
  FaFingerprint,
  FaGlobe,
  FaGlobeEurope,
  FaGolfBall,
  FaGoogleDrive,
  FaHandHolding,
  FaHandHoldingUsd,
  FaHandPointUp,
  FaHandshake,
  FaHistory,
  FaLaptop,
  FaLaptopCode,
  FaLaptopMedical,
  FaLock,
  FaLuggageCart,
  FaMailBulk,
  FaMapMarkedAlt,
  FaMobile,
  FaMobileAlt,
  FaMusic,
  FaNetworkWired,
  FaPager,
  FaPencilRuler,
  FaPersonBooth,
  FaPoll,
  FaPortrait,
  FaProjectDiagram,
  FaRobot,
  FaSatellite,
  FaSdCard,
  FaSearch,
  FaSearchLocation,
  FaSearchPlus,
  FaServer,
  FaShareAltSquare,
  FaShoppingCart,
  FaStumbleuponCircle,
  FaSwatchbook,
  FaTabletAlt,
  FaThumbsUp,
  FaTimesCircle,
  FaTv,
  FaUser,
  FaUserCog,
  FaUserGraduate,
  FaUserTag,
  FaUsers,
  FaUsersCog,
  FaWaveSquare,
  FaWindowMaximize,
  FaWindowRestore,
  FaYoutube,
} from "react-icons/fa";
import ican1 from "../Assets/ican-6.webp";
import ican2 from "../Assets/ican-2.webp";
import ican3 from "../Assets/ican-4.webp";
import ican4 from "../Assets/ican-5.webp";
import ican5 from "../Assets/ican-3.webp";
import ican6 from "../Assets/php.webp";
export const ecommerceData = [
  {
    icon: FaCartPlus,
    color: "#e33f70",
    title: "Specialized In eCommerce",
    description:
      "Out of the wide range of services we provide, eCommerce web development is our specialization.",
    titleTwo: "Enterprise SEO",
    descriptionTwo:
      "Enterprise SEO  we provide, eCommerce web development is our specialization.",
    titleThree: "Extensive Experience",
    descriptionFour:
      "A team of experienced, creative and innovative design experts.",
    titleFour: "Creative Minds",
    titleFive: "Experienced Team ",
    descriptionFive:
      "We have relevant industry-exposure to build high-end enterprise-scale mobility solutions. Instead of using a template theme, we can integrate custom features to ensure that your business app stands out from the crowd. ",
  },
  {
    icon: FaBookOpen,
    color: "#e33f70",
    title: "Innovative Designs",
    description:
      "Our innovative eCommerce developers ensure that your eCommerce website is always up-to-date with the latest design trends.",
    titleTwo: "Local SEO",
    descriptionTwo:
      "Local SEO Our innovative eCommerce developers ensure that your eCommerce website is always .",
    titleThree: "Brand Focussed",
    descriptionFour:
      "Delivering bespoke promotional materials for over a decade.",
    titleFour: "Extensive Experience",
    titleFive: " Advanced Toolkit",
    descriptionFive:
      "Our team is well-versed with the advanced tools like Panache, Supernova, Codemagic, Amplitude, Sylph, Count.ly, AppsFlyer, etc. Such an extensive knowledge helps us to drive innovation in your app.innovation in your app. ",
  },
  {
    icon: FaClock,
    color: "#e33f70",
    title: "On-Time Delivery",
    description:
      "As the top eCommerce website design company, we ensure timely delivery of your online store to meet your deadlines.",
    titleTwo: "Ecommerce SEO",
    descriptionTwo:
      "Ecommerce SEO As the top eCommerce website design company we ensure timely delivery of your online store.",
    titleThree: "Reasonable Costs",
    descriptionFour: "We focus on your brand niche to maximise its potential.",
    titleFour: "Brand Focussed",
    titleFive: " Competitive Rate",
    descriptionFive:
      "In today’s digitally advanced age, a mobile app is a necessity for any business. That’s why we offer a distinct solution at a competitive rate for SMBs (Small and Medium-sized Businesses) and large enterprises. Our tailored solution allows you to pay for just what you want. So you can effectively save some bucks. ",
  },
  {
    icon: FaUser,
    color: "#e33f70",
    title: "Dedicated Team",
    description:
      "We have a large pool of experienced eCommerce developers dedicated to creating amazing eCommerce websites tailored to your needs.",
    titleTwo: "Keyword research",
    descriptionTwo:
      "Keyword research We have a large pool of experienced eCommerce developers for amazing eCommerce website development.",
    titleThree: "Business Requirements",
    descriptionFour:
      "Our brochure & flyer design services are reasonably priced.",
    titleFour: "Reasonable costs",
    titleFive: " Scalable Solution",
    descriptionFive:
      "We support your business growth. That’s why we offer a highly scalable digital solution. You can launch an MVP first then scale it up as per your budget and requirements. You can also get a robust application and later extend its features and functionalities without any hassles. ",
  },
];

export const appData = [
  {
    color: "#e33f70",
    icon: FaCartPlus,
    title: "Application Enhancement",
    description:
      "web application maintenance services. Take a look at the support & services we cover",
    iconTwo: FaThumbsUp,
    titleTwo: "Agility",
    descriptionTwo:
      "Get quick, lead-oriented, industry-specific social media marketing services that work",
  },
  {
    icon: FaBookOpen,
    color: "#e33f70",
    title: "Hosting Migration & Support",
    description:
      " Hosting Migration & Support Our innovative eCommerce developers ensure that your eCommerce website is always .",
    iconTwo: FaCog,
    titleTwo: "Management",
    descriptionTwo:
      "We have dedicated social media marketers for campaign monitoring, upgrading and .",
  },
  {
    icon: FaClock,
    color: "#e33f70",
    title: "Database Optimization",
    description:
      "Database Optimization As the top eCommerce website design company we ensure timely delivery of your online store.",
    iconTwo: FaBattleNet,
    titleTwo: "Consistency",
    descriptionTwo:
      "We keep upgrading our strategies to maintain consistency in our performance.",
  },
  {
    icon: FaUser,
    color: "#e33f70",
    title: "Code Review & Optimization",
    description:
      "Code Review & Optimization We have a large pool of experienced eCommerce developers for amazing eCommerce website development.",
    iconTwo: FaGoogleDrive,
    titleTwo: "Results-Driven",
    descriptionTwo:
      "All our activities are driven by the main objective – Generating more business.",
  },
];
export const cmsData = [
  {
    icon: FaLaptopCode,
    color: "#e33f70",
    title: "CMS Web Design & Development",
    description:
      "We can build you a mobile-responsive website that is completely up-to-date with the latest features and provides a smooth user interface.",
  },
  {
    icon: FaWindowRestore,
    color: "#e33f70",
    title: "CMS Upgrade And Integration",
    description:
      "We are always here to help when you need a version upgrade of your existing website. We will also integrate new plugins and components if you want.new plugins and components if you want..",
  },
  {
    icon: FaLaptopCode,
    color: "#e33f70",
    title: " Existing CMS Migration",
    description:
      "Our expert developers can help you, if you wish to take your website from one CMS platform to another.",
  },
  {
    icon: FaCogs,
    color: "#e33f70",
    title: "Support And Maintenance",
    description:
      "You can reach us any time for any kind of assistance regarding your CMS website. We are always in all ears.",
  },
];
export const cards = [
  {
    icon: FaSearch,
    title: "Research and Analysis",
    description:
      "Before starting out, our web developers conduct a thorough analysis to comprehend your requirement, target audience, and.",
  },
  {
    icon: FaLaptopCode,
    title: "Design",
    description:
      "With adequate insights into your expectations, we create a wireframe highlighting crucial aspects of the application.",
  },
  {
    icon: FaMobile,
    title: "Application Development",
    description:
      "After the design is approved, we proceed to the application development process. We will diligently create the front and backend with relevant.",
  },
  {
    icon: FaThumbsUp,
    title: "Quality Assurance",
    description:
      "Our rigorous QA procedure throughout the development and deployment cycle aims at keeping your application user-friendly, secured, and seamless at all times.",
  },
  {
    icon: FaGlobeEurope,
    title: "Deployment",
    description:
      "Once the web application is finalized, we proceed to the next step - deploying the app in the online environment. That way, you need not take any hassle.",
  },
  {
    icon: FaMusic,
    title: "Support & Maintenance",
    description:
      "We take the extra mile to keep your web app secured and user-friendly at all time. You can always reach us for any kind of maintenance and support.",
  },
];

export const developmentData = [
  {
    imgSrc: ican1,
    icon: FaLaptop,
    alt: "OpenCart Development",
    title: "OpenCart Development",
    titletwo: "Website Analysis",
    description:
      "One of the main perks of OpenCart is easy customization. Thanks to the use of AJAX, websites developed using OpenCart are extremely lightweight. It supports over 14000 extensions that can be plugged-in effectively to enhance functionalities of the online store. As a reputable eCommerce website development company in India, we can help you create a beautiful online store at a reasonable cost. level of security due to its PA-DSS compliance.",
    descriptiontwo:
      "Using various paid tools like SEMrush & Ahrefs, we review your website to check if it matches the parameters of search engine algorithms. Website URL structure checking & modifying, checking responsiveness on cross-devices, webpage indexation issue checking & fixing, sitemap analysis & modifying - we extend a complete range of technical SEO service.",
    iconthree: FaDollarSign,
    titlethree: "Paid Search Management",
    descriptionthree:
      "Our AdWords consultants specialize in bidding on the right keywords and delivering high-value traffic sculpting in Google & Bing Ads while also focusing on precise intent-targeting to ensure that the ads can drive qualified leads. Our in-house copywriters can create compelling, conversion-driven, custom copies to attract the audience.",
    iconFour: FaBook,
    titleFour: "Single Page Application (SPA)",
    descriptionFour:
      "Our React services help to build Single Page Applications that can load a single HTML page and update it dynamically as and when the user interacts with the page. The SPA is responsive and does not require constant page reloads from the server. This makes the page consume less bandwidth and remains lightweight. Avail our dynamic React services to develop effective and efficient SPAs. ",
    titleFive: "How can I practice Machine Learning with RoR? ",
    descriptionFive:
      " If you search on Github for “Machine Learning Ruby,” you will come across numbers of Ruby-based ML tools. Yes, Ruby is not an ideal choice for machine learning processes, but Ruby is actually a good choice for user interaction and API functions. However, Java works well for the classification tasks and actual training as it works perfectly well.",

  },
  {
    imgSrc: ican2,
    icon: FaSearch,
    alt: "Magento Development",
    title: "Magento Development",
    description:
      "Magento is one of the widely used platforms for eCommerce website development that can store thousands of products and handle heavy traffic seamlessly. Due to its modular structure, it allows deep customization of both the front-end and back-end. It is effective for building an online marketplace where multiple vendor stores can be set up and providing an unmatched level of security due to its PA-DSS compliance.",
    titletwo: "On-Page SEO",
    descriptiontwo:
      "After conducting a thorough competitor and keyword research, we will adopt various measures within your website. Our activities will include optimizing your website content, meta tags, image alt tags, writing header tags, implementing schema markup, creating & implementing robots.txt file, fixing broken links, and more.fixing broken links, and more.",
    iconthree: FaPager,
    titlethree: "PPC Landing Page Creation",
    descriptionthree:
      "An effective PPC campaign generates high-quality leads and demands a well-crafted & optimized landing page. Without an information-rich landing page, your investment may not sprout the best results. As a reliable website development company, we can create and optimize your landing page to enhance conversions.",
      iconFour:FaProjectDiagram ,
    titleFour: "Virtual DOM Concept",
    descriptionFour:
      " The React development process creates a virtual Document Object Model or virtual DOM in the form of a data structure (read node tree) that lists elements as objects. This renders the updation of virtual DOMs faster by the combination of an efficient update of the node tree and observables to find the modified components.",
      titleFive: " If Ruby does not match Machine Learning, then what’s the alternative?",
    descriptionFive:
      " It’s Python. Python is a general-purpose, interpreted, and high-level OOPs based dynamic programming language that has always been the recommended web framework for data sciences as it has excellent numbers of packages to offer such as TensorFlow, pandas, Keras, and NumPy. The whole new project set-up can be done quickly using these computations.",
  },
  {
    imgSrc: ican3,
    icon: FaBook,
    alt: "WordPress Development",
    title: "WordPress Development",
    description:
      "WordPress websites are lightweight, faster to build and extremely easy to manage. We use WooCommerce which is the most popular eCommerce plugin for WordPress with 30% of the market share. Built with REST API, WooCommerce is highly scalable and offers secure online payment, configurable shipping options and many other features required for a fully functional eCommerce website.unmatched level of security due to its PA-DSS compliance.",
    titletwo: "Competitor Analysis",
    descriptiontwo:
      "An understanding of your competitors’ strategies will help us to identify the opportunities for your business. With this objective, we delve deeper to study your business niche and competitors. This step is crucial to ensure that our efforts are put in the right direction and can offer you a competitive edge.",
    iconthree: FaUsers,
    titlethree: "Paid Social",
    descriptionthree:
      "Our PPC managers optimize campaigns for social media like Facebook, LinkedIn, Instagram, etc. with precise interest and demographic targeting, we can help to boost your brand visibility and help to reach a wide audience. Our expertise lies in creating carousel ads, dynamic product ads, photo, video, and story ads.",
      iconFour:FaServer ,
    titleFour: "Server Rendering ",
    descriptionFour:
      "The React development process helps to render a Single Page Application to the client side instead of the need to load the entire JavaScript repeatedly. This leads to a faster loading of the page. Besides, the page content can be easily accessed by the search engine crawler programs leading to its better optimization. ",
      titleFive: " Automation of tasks",
    descriptionFive:
      "Ruby on Rails has been a preferred choice for the developers because it assists with task automation, which is a blessing in the technology world. All routes are set up the Rails itself; it means you need just to follow the rails to get all the clues and tips. The MVC is easily stringed with the help of routes driven by the Rails through automation. ",
  },
  {
    imgSrc: ican4,
    icon: FaPersonBooth,
    alt: "PrestaShop Development",
    title: "PrestaShop Development",
    description:
      "When using PrestaShop, you do not have to deal with a lot of technical aspects or complex coding. It is free, lightweight and easy to use. Besides, it offers multilingual support which allows you to sell your products or services in a global market. Our professional eCommerce website developers are well acquainted with this platform and can build unique eCommerce websites for your business.",
    titletwo: "Content Marketing",
    descriptiontwo:
      "Off-page SEO helps in earning quality backlinks and conveying the importance & reliability of your site to the search engines. Writing & publishing blogs/articles, classifieds, infographic, press release, creating videos, social bookmarking, interacting with people on forums - we do it all. Our consistent efforts amplify brand awareness & drive referral .",
    iconthree: FaTv,
    titlethree: "Display Ads",
    descriptionthree:
      "Display ad is an important form of paid advertisement. Our effective display ad strategies include creating attractive and custom visuals paired with choosing the appropriate web pages, games and videos to place the ads. Our PPC team work hand-in-hand with our in-house designers to ensure each ad is unique and conversion-driven.",
      iconFour: FaEdit,
    titleFour: " Declarative",
    descriptionFour:
      " React makes it easier to create interactive UIs and updates the right components when the data in your SPA changes. The declarative views enhance the readability of your codes besides rendering them easier to debug. The expert React developers at Webguru create engaging and quick loading UIs enabling a seamless browsing experience.",
      titleFive: "Uncompromising Architecture ",
    descriptionFive:
      "ROR is the best example of uncompromising architecture in developing the web application framework. When you choose ROR, you get the best web architecture to work with. It is just awesome to get along with it for developing new web services and applications. ",
  },
  {
    imgSrc: ican6,
    icon: FaLaptopCode,
    alt: "Magento Development",
    title: "Custom Development",
    description:
      "Do you need your online marketplace to stand out from the crowd with features and functionalities specific to your business? Engaging our experienced developers for building a custom eCommerce solution can work wonder for you then. We can craft feature-rich, easily navigable, user-friendly and lightweight eCommerce website for your business. We use frameworks like Laravel, CakePHP, CodeIgniter, NodeJS and databases like MySQL.",
    titletwo: "Keyword research",
    descriptiontwo:
      "Targeting relevant keywords means extending the scope of winning relevant traffic. Our experts will conduct thorough keyword research and make a list that best suits your website and today’s competitive landscape. We will share the target keyword list with you along with our recommendation. Then, the ones selected by you will be used for on-page and off-page SEO.",
    iconthree: FaShoppingCart,
    titlethree: "Shopping Ads",
    descriptionthree:
      "Visually more appealing than text-only ads, shopping ads can help you explore sales opportunity in an engaging manner. The rise in mobile shopping habit has furthered the demand for this ad strategy. We adopt a scientific approach toward Google shopping campaign management to improve your profit margins.",
      iconFour:FaHandHolding ,
    titleFour: "Reusing Components ",
    descriptionFour:
      "React helps to create a self contained library of modules called components to render each and every part of the web page. These structured and secure components are able to manage their own state and can be reused at will. This leads to a faster development and maintenance of the web application. ",
      titleFive: "2x Faster ",
    descriptionFive:
      " The leaner code-base, as well as the use of existing plugins along with modular designs, turn ROI as 2X faster for developing new web service frameworks such as apps and services. You can trust and recommend the framework for startups, who are looking to design their independent web service portal.",
  },
  {
    imgSrc: ican5,
    icon: FaHandHoldingUsd,
    alt: "Shopify Development",
    title: "Shopify Development",
    description:
      "Shopify is a fully hosted eCommerce platform that helps to set up an online store real quick. With 100+ professional themes and several useful plugins, it takes minimal development efforts. Our Shopify developers can build and deliver an eCommerce storefront for your business with features like Drop Shipping, Fraud Prevention, SEO optimized, Social Media Integration and more.",
    titletwo: "Set Up Business Listing",
    descriptiontwo:
      "An entry in a business listing makes a site easier to find out and creates an additional backlink. As an integral part of search engine optimization, we will set up Google My Business account for your business and include relevant details. We will also enlist your business details in reputed online business directories to drive more visibility and traffic to your website.",
    iconthree: FaYoutube,
    titlethree: "YouTube Ads",
    descriptionthree:
      "Video content is rapidly becoming the front runner in digital marketing. Its easy-to-digest format gives people a break from consuming a surplus of textual information. Our entertaining high-quality video ads are sure to bring you the desired results.",
      iconFour: FaPoll,
    titleFour: "Performance ",
    descriptionFour:
      " React speeds up the user interface without requiring any effort to optimize its performance. Our React development services use an application architecture called Flux controls to update the page view for the user as well as control the application workflow.",
      titleFive: " Stack of Rail Libraries",
    descriptionFive:
      " Rails libraries have always been supportive of the developers in getting the exact information while creating web applications. It is one of the most recommended libraries for creating interactive and engaging web services.",
  },
];
export const businessData = [
  {
    icon: FaCartPlus,
    color: "#e33f70",
    title: "Tailored to Business Requirement",
    description:
      "Out of the wide range of services we provide, eCommerce web development is our specialization.",
  },
  {
    icon: FaBookOpen,
    color: "#e33f70",
    title: "Cross Device Friendly",
    description:
      "Cross Device Friendly Our innovative eCommerce developers ensure that your eCommerce website is always .",
  },
  {
    icon: FaClock,
    color: "#e33f70",
    title: " Optimized for Search Engine",
    description:
      "Optimized for Search Engine As the top eCommerce website design company we ensure timely delivery of your online store.",
  },
  {
    icon: FaUser,
    color: "#e33f70",
    title: "Smooth User Experience",
    description:
      "Smooth User Experience We have a large pool of experienced eCommerce developers for amazing eCommerce website development.",
  },
];
export const corporateData = [
  {
    icon: FaCartPlus,
    color: "#e33f70",
    title: "MOTION UI",
    titletwo: "Enterprise SEO",
    description:
      "Out of the wide range of services we provide, eCommerce web development is our specialization.",
    descriptiontwo:
      "Enterprise SEO we provide, eCommerce web development is our specialization.",
    iconthree: FaBattleNet,
    titlethree: "Detailed Brand Analysis",
    descriptionthree:
      " Logo design is undertaken after a thorough analysis of your brand.",
    titleFour: "Effective Scripts",
    descriptionFour:
      "Logo design is undertaken after a thorough analysis of your brand.",
    iconFive: FaUserGraduate,
    titleFive: "Proven Record",
    descriptionFive:
      "Our team has provided PHP programming services to many satisfied clients globally.",
    iconSix: FaBattleNet,
    titleSix: "Impeccable services ",
    descriptionSix:
      "We have a proven track record in providing quality services. ",
      iconSeven: FaUserGraduate,
      titleSeven: ' Dedicated Team',
      descriptionSeven: ' You will get a dedicated project manager and a whole team at your disposal during the development process.',
      iconEight:FaMobile ,
      titleEight:" Top notch services",
      descriptionEight: " We have a proven track record in providing top quality services.",
  },
  {
    icon: FaBookOpen,
    color: "#e33f70",
    title: " CHATBOTs",
    titletwo: " Local SEO",
    description:
      "Our innovative eCommerce developers ensure that your eCommerce website is always .",
    descriptiontwo:
      "Local SEO Our innovative eCommerce developers ensure that your eCommerce website .",
    iconthree: FaUser,
    titlethree: "Experienced Designers",
    descriptionthree:
      " Our designers have been delivering high end custom logo designs since 2005.",
    titleFour: "Production Value",
    descriptionFour: "Our videos have high quality production aesthetics.",
    iconFive: FaCog,
    titleFive: "Best Technologies",
    descriptionFive:
      "We only use updated and relevant technologies for your PHP development projects.",
    iconSix: FaUser,
    titleSix: " Quick Turnaround Time",
    descriptionSix:
      "Our quick turnaround time offers you a competitive edge in the market ",
      iconSeven: FaCog,
      titleSeven: ' 3rd Party Integrations',
      descriptionSeven: ' Our apps allow seamless integration of third-party APIs and other codes.',
      iconEight:FaSatellite ,
      titleEight:"Unique Solutions ",
      descriptionEight: " We develop and provide only best solutions fitting your need.",
  },
  {
    icon: FaClock,
    color: "#e33f70",
    title: "  HAMBURGER MENU",
    titletwo: " Ecommerce SEO",
    description:
      "As the top eCommerce website design company we ensure timely delivery of your online store.",
    descriptiontwo:
      "Ecommerce SEO As the top eCommerce website design company we ensure timely delivery of your online store.",
    iconthree: FaAccusoft,
    titlethree: "Multiple Packages",
    descriptionthree:
      " We have many packages to suit various business logo design needs.",
    titleFour: "Quality Audio",
    descriptionFour: "Some of the best voice-overs and contemporary music.",
    iconFive: FaLaptopCode,
    titleFive: "Clean coding",
    descriptionFive:
      "All our coding is debugged and tested multiple times for the best end result.",
    iconSix: FaAccusoft,
    titleSix: " Competitive Rate",
    descriptionSix:
      " Hire Node JS developers from us to develop a future-ready web app at a cost-effective rate.",
      iconSeven:FaLaptopCode  ,
      titleSeven: 'Reasonable Pricing',
      descriptionSeven: ' We provide quite and cost effective Swift app development services as compared to the competitors in the market.',
      iconEight:FaBook ,
      titleEight:"Requirement Analysis ",
      descriptionEight: " We ask questions in detail to understand your requirement precisely.",
  },
  {
    icon: FaUser,
    color: "#e33f70",
    title: "INFORMATIVE VIDEO",
    titletwo: " Keyword research",
    description:
      "We have a large pool of experienced eCommerce developers for amazing eCommerce website development.",
    descriptiontwo:
      "Keyword research We have a large pool of experienced eCommerce developers for amazing eCommerce website development.",
    iconthree: FaBook,
    titlethree: "Multiple Design Concepts",
    descriptionthree:
      " We provide varied and multiple design concepts for our clients to choose from.",
    titleFour: "Short and Precise",
    descriptionFour: "Informative and concise matter to keep users engaged.",
    iconFive: FaUser,
    titleFive: "Large Team",
    descriptionFive:
      "Work never stops with sudden developer exits as we have a large team.",
    iconSix: FaBook,
    titleSix: "Customised approach ",
    descriptionSix:
      " We treat every project uniquely to provide customised solutions.",
      iconSeven: FaUser,
      titleSeven: ' Quick Approval',
      descriptionSeven: ' Our iOS apps get approved quickly to be published in the App Store for download.',
      iconEight:FaBlenderPhone ,
      titleEight:" Customised approach",
      descriptionEight: "We treat every project uniquely to provide customised solutions. ",
  },
];
export const cuttingData = [
  {
    icon: FaBook,
    color: "#e33f70",
    title: "Appealing layout",
    description:
      "Our website layouts are sleek, minimalistic, trendy yet highly appealing. This evokes a positive impression in the users’ mind and enables them to easily grasp the content without straining their eyes..",
    icontwo: FaCog,
    titletwo: "Enterprise SEO",
    descriptiontwo:
      "If you are looking to grow your business nationwide, our advanced skillset can deliver you just that. With high-end tools and proven process, our experts can help you generate a strong market position and drive more conversions.",
    iconthree: FaSearchPlus,
    descriptionthree:
      "We research and develop a list of keywords that represent your products or services.",
    iconfour: Fa500Px,
    titlefour: "Define Goal & Objectives",
    descriptionfour:
      "Every business has different goals and objectives. Accordingly, we plan and develop unique content marketing strategies.",
    iconfive: FaMailBulk,
    descriptionfive:
      "Innovative, customized designs to build recognizable and powerful corporate identities.",
    iconSix: FaBook,
    titleSix: " Bi-Fold Brochures",
    descriptionSix:
      "Bi-fold brochures have 2 folds (4 panels). It is similar to a booklet and are fairly inexpensive to print. ",
    iconSeven:FaAutoprefixer ,
    titleSeven: "Readability " ,
    descriptionSeven: "Less code and better readability is one of Swift’s main principles. " ,
    iconEight: FaGolfBall ,
    titleEight: ' Ideation' ,
    descriptionEight: 'Idea refining and streamlining to make the app meet business objectives. '
  },
  {
    icon: FaLaptop,
    color: "#e33f70",
    title: "Dynamic Functionalities",
    description:
      "Leveraging the full potential of PHP, JavaScript, CSS and 3rd party APIs, we can create interactive and engaging dynamic web applications. Our custom sites can include picture album & video gallery, CTA buttons, search bar, micro-interactions, and much more.",
    titletwo: "Ecommerce SEO",
    icontwo: FaCashRegister,
    descriptiontwo:
      "We strive to increase sales at your e-store. Our activities include site architecture, robots.txt & product image optimization, keyword mapping, canonicalization analysis, product page schema setup, broken link correction, enhanced eCommerce analytics implementation etc.",
    iconthree: FaDollarSign,
    descriptionthree:
      "Our PPC Management team writes ads incorporating the targeted keywords.",
    iconfour: FaSearch,
    titlefour: "Conduct Persona Research",
    descriptionfour:
      "By understanding your target audience, we develop relevant and valuable content that will attract and engage.",
    iconfive: FaUsersCog,
    descriptionfive:
      "As a leading logo design company India, we can create eye-catchy logo for your brand.",
    iconSix: FaBookmark,
    descriptionSix:
      " As the name implies, these brochures have 3 folds (6 panels) and offer more space to present any information.",
    titleSix: " Tri-Fold Brochures",
    iconSeven: FaSdCard,
    titleSeven: " Memory" ,
    descriptionSeven: "Apps build on Swift programming language consumes less memory. " ,
    iconEight:FaPencilRuler ,
    titleEight: 'UI/UX Design ' ,
    descriptionEight: 'Designing an effective and interactive UI/UX to ensure engagement. '
  },
  {
    icon: FaProjectDiagram,
    color: "#e33f70",
    title: "  Scalability",
    description:
      "We encourage business growth. Our web solutions are highly scalable so you can extend the features and functionalities of your website as your business grows. This is a highly advantageous feature for a corporate website.",
    icontwo: FaMapMarkedAlt,
    titletwo: "Local SEO",
    descriptiontwo:
      "Geo-targeted local SEO is highly effective to prepare you for location-oriented search. As about 46% of Google searches have a local intent, engaging local SEO services is beneficial for business growth. We conduct thorough demographic research before offering the service.",
    iconthree: FaCompass,
    descriptionthree:
      "We identify the target customers and set the demographic and time accordingly to display the ads.",
    iconfour: FaCalendarAlt,
    titlefour: "Content Calendar",
    descriptionfour:
      "We develop a content calendar to sell the right words at the right moment to the right audience.",
    iconfive: FaClock,
    descriptionfive:
      "Attention to detail to ensure that the highest quality of service is delivered and time-lines are adhered to.",
    iconSix: FaBookOpen,
    descriptionSix:
      "These brochures are inward folding like a gate on one big panel and are primarily used in high-end marketing campaigns. ",
    titleSix: " Gate Fold Brochures",
    iconSeven:FaMobile ,
    titleSeven: "Data Comply " ,
    descriptionSeven: " Swift can comply data at a faster pace than other languages." ,
    iconEight: FaCog,
    titleEight: ' QA & Testing',
    descriptionEight: ' Rigorous testing of concepts, visual elements and features.'
  },
  {
    icon: FaBookMedical,
    color: "#e33f70",
    title: "SEO-friendly Content",
    description:
      "Developing a website will not do the trick of driving more traffic and producing brand authority. Search engine optimization is crucial for this. We write web page content in an SEO-friendly manner to give you a competitive edge. We also offer full-spectrum of SEO services.",
    iconthree: FaHandHoldingUsd,
    descriptionthree:
      "We ensure proper reach of the ads, lowest possible expenses and the best returns on investments",
    iconfour: FaBook,
    titlefour: "Content Optimization",
    descriptionfour:
      "As a reliable company providing professional SEO services, we optimize the content with relevant keywords to enhance search engine ranking.",
    iconfive: FaEnvelope,
    descriptionfive:
      "Proper communication with the clients before the design process starts to help with better understanding of the requirements.",
    iconSix: FaBookMedical,
    descriptionSix:
      " These brochures fold into a ‘Z’ pattern and are ideal for impressing readers through a stylish and unique appearance.",
    titleSix: " Z-Fold Brochure",
    iconSeven: FaLock,
    titleSeven: "Security " ,
    descriptionSeven: "The development of structure and dialect in Swift ensure fewer crashes. " ,
    iconEight: FaAtlas,
    titleEight: ' Planning',
    descriptionEight: ' Developing strategies, resource allocation and prototyping.'
  },
  {
    icon: FaMobile,
    color: "#e33f70",
    title: "Responsive Solution",
    description:
      "Cross-device friendliness is no more a choice but a necessity for the modern-day websites. Our in-house artisans ensure that your website delivers an unparalleled user experience regardless of the resolution of a device or the screen size.",
    iconthree: FaUser,
    descriptionthree:
      "Our PPC experts continuously monitor performance of the ads and set optimum bid values.",
    iconfour: FaBullhorn,
    titlefour: "Content Promotion",
    descriptionfour:
      "Be it the owned, earned or shared media, we promote the content across all platforms through the SEO, SMM, and email marketing techniques.",
    iconfive: FaUserTag,
    descriptionfive:
      "Creative approach during the designing process and keeping the client in constant loop for effective feedback.",
    iconSix: FaAddressBook,
    descriptionSix:
      "Commonly used for step by step instructions or for educational material, this is a layout that folds in on itself. ",
    titleSix: "Four panel Roll Fold ",
    iconSeven:FaClock ,
    titleSeven: " Speed" ,
    descriptionSeven: "Speed The Swift apps get developed quickly due to simple coding and syntax. " ,
    iconEight:FaMobile ,
    titleEight: 'Swift Coding ',
    descriptionEight: ' Writing & implementing simple yet functional codes & syntax.'
  },
  {
    icon: FaLaptopCode,
    color: "#e33f70",
    title: "Excellent UI / UX",
    description:
      "Every engaging website is a combination of an out-of-the-box UI design and excellent technical functionalities. All our websites combine the duo to drive a superb UX.",
    iconthree: FaBook,
    descriptionthree:
      "We follow up with periodical auditing and restrategize the campaigns as needed.",
    iconfour: FaFileAudio,
    titlefour: "Content Audit",
    descriptionfour:
      "We conduct thorough audit on our previous performances and improvise our existing strategies accordingly.",
    iconfive: FaFileInvoiceDollar,
    descriptionfive:
      "Services provided at some of the most reasonable and competitive rates within the industry.",
    iconSix: FaAtlas,
    descriptionSix:
      "Also known as pocket or folder brochures, these have spaces inside them where relevant product inserts can be put. ",
    titleSix: "Insert and Folder Brochures ",
    iconSeven: FaTimesCircle,
    titleSeven: " Non-Restricted" ,
    descriptionSeven: "Besides iOS, Swift can also create apps for other platforms e.g. Android. " ,
    iconEight: FaLaptopMedical,
    titleEight: 'Deployment ',
    descriptionEight: ' Following a systematic plan for easy deployment of the app.'
  },
];

export const customerData = [
  {
    icon: FaBook,
    title: "Custom web application",
    description:
      "Equipped with the advanced technologies, we can craft customized, spectacular and user-friendly web applications to streamline your business. We work on both small and large-scale level.",
  },
  {
    icon: FaLuggageCart,
    title: "eCommerce solutions",
    description:
      "Our eCommerce websites feature smooth UI, attractive product catalogue, shopping cart, SSL certificate, and robust functionalities to offer a top-notch UX. Our solutions are based on robust .",
  },
  {
    icon: FaBookMedical,
    title: "Web Portal Development",
    description:
      "We can build a platform where you can easily communicate with your audience. We offer a wide range of B2B & B2C portal development services including news portal, job, mailing, cab booking, shopping portal, etc.",
  },
  {
    icon: FaLaptopCode,
    title: "Website redevelopment",
    description:
      "An outdated website can negatively impact your business. We offer a complete overhaul in code and appearance of the website. We ensure to craft your site with the latest features and functionalities.",
  },
  {
    icon: FaBook,
    title: "Custom API Development",
    description:
      "Extend your existing website’s functionalities with 3rd party APIs (Application Programming Interface). We can integrate various types of APIs including payment, video, chat, etc.",
  },
  {
    icon: FaUserCog,
    title: "Website Optimization",
    description:
      "Our developers and hosting experts can optimize your website in an SEO-friendly manner. It will help to improve your site’s visibility on the Search Engine Result Page (SERP).",
  },
  {
    icon: FaThumbsUp,
    title: "Social Media Integration",
    description:
      "Integrating social media platforms on your website enables the users to check out your social media presence directly from the site and promote your brand. We can implement the same for you.",
  },
  {
    icon: FaHandHoldingUsd,
    title: "Support & Maintenance",
    description:
      "A stunning UI cannot drive a top-notch UX if your site is not free from bugs or other technical glitches. We perform automated and manual review and fix the issues to ensure that your site is highly functional at all time.",
  },
];
export const rangeData = [
  {
    icon: FaRobot,
    color: "#e33f70",
    title: "Android Architecture Pattern",
    description:
      "We recommend and use the MVVM (Model-View-ViewModel) pattern. Model abstracts data source to acquire and save data, View informs about the users’ activity and ViewModel reveals the stream of data. Thus, it creates a balanced ecosystem to maintain codes and enhance readability.",
    iconTwo: FaStumbleuponCircle,
    titleTwo: "Faster I/O operations ",
    descriptionTwo:
      " Node JS is built to handle asynchronous I/O operations using a single thread called the Event Loop ensuring faster reading or writing to the database, network connections or file systems.",
    iconThree: FaLaptop,
    titleThree: " Less Coding ",
    descriptionThree:
      " .NET is one of the greatest platforms if you want an application with less coding because this framework works on the object-oriented programming model. And this model eliminates unnecessary codes as well as involves some less coding opportunities for the developers.",
    iconFour: FaLaptop,
    titleFour: " ASP.NET Development Services ",
    descriptionFour:
      "Our developers are equipped with deep experience of creating large-scale ASP.NET web applications as well as some high-performance web sites with the latest ASP.NET flavors such as ASP.NET 4.5, ASP.NET MVC 4 and ASP.NET Core. ",
    iconFive: FaLaptop,
    titleFive: "Custom App Development ",
    descriptionFive:
      " With our innovative ideas and long experience, we can tailor industry-standard web portal for our customers. We can build versatile and user-friendly web applications including robust eCommerce apps to take your business to the peak.",
    iconSix: FaLaptopCode,
    titleSix:"Single Codebase For All Platforms ",
    descriptionSix:
      " Rewriting code for various platforms is not required with the Ionic mobile app development helping save money, time and effort.save money, time and effort ",

  },
  {
    icon: FaSearchLocation,
    color: "#e33f70",
    title: "GPS Integration",
    description:
      "Depending on your requirement, we can add a GPS feature in your app. This ensures an ease of navigation. It is highly user-friendly and can give you a competitive edge over other applications.",
    iconTwo: FaClock,
    titleTwo: " Faster data streaming",
    descriptionTwo:
      "Node JS uses HTTP request and response as data streams resulting in faster processing of files during the uploading process. This power can be leveraged in deploying real-time video and audio recording. ",
    iconThree: FaWindowMaximize,
    titleThree: "Deployment  ",
    descriptionThree:
      "Moreover, the .NET framework has made the deployment process easier post-development because it is equipped with the features such as controlled code sharing, private components, side-by-side versioning, no-impact applications, and partially trusted code etc. ",
    iconFour: FaGolfBall,
    titleFour: " .NET-Based Products Customization ",
    descriptionFour:
      " At TIS, we possess an experienced and dedicated team for .NET-based products customization along with the extension and integration services. So, you can reach to your own ASP.NET Development Agency  for reliable product customization needs.",
    iconFive: FaBook,
    titleFive: " Real-time Chat Apps",
    descriptionFive:
      " We can help you create real-time apps like instant messengers or chat apps. Our skilled developers leverage excellent features of Angular for frontend and build the backend of the apps with Node.js.to extend the functionalities of your ",
      iconSix: FaCog,
      titleSix:" Optimisation Of Your Product",
      descriptionSix:
        "Puts focus on building the functionalities rather than optimizing UI/UX for different platforms, resulting in a uniform cross-platform experience. ",
  },
  {
    icon: FaBullhorn,
    color: "#e33f70",
    title: "Push Notifications",
    description:
      "Push notification is a great way to attract users to open the app. Through this, you can promote new stock and discount offers, remind the users to check out their saved cart, ask them to perform a scheduled task, and so on. This is a highly useful digital marketing feature and we can add it in your app.",
    iconTwo: FaCheckCircle,
    titleTwo: "Ease of coding ",
    descriptionTwo:
      " Our Node JS, developers use JavaScript for both client and server side scripting, resulting in ease of data transport between server and browser.",
    iconThree: FaHandshake,
    titleThree: "Reliability  ",
    descriptionThree:
      "ASP.NET is the most reliable platform because you can test your code side-by-side to ensure that everything will work in the manner it is designed to be. That means it has fewer chances of errors as well as it requires less maintenance. ",
    iconFour: FaLaptopCode,
    titleFour: " NET Desktop Application Development ",
    descriptionFour:
      " We create state-of-the-art desktop apps with our years of experience in developing Windows GUI applications, Windows Smart Client apps using Windows Presentation Foundation, Windows Console applications, as well as Windows Store Apps for Windows 8.",
    iconFive: FaHandPointUp,
    titleFive: " API Development",
    descriptionFive:
      "We craft scalable and advanced enterprise-level Angular API development service. Get custom API solutions from us involving integration, deployment, documentation, and maintenance. We can develop robust encryptions for. ",
      iconSix: FaHistory,
      titleSix:" Smoother And Faster",
      descriptionSix:
        " The mobile apps resulting from ionic development have a smoother, faster and a more pleasant user experience compared to other platforms.",
  },
  {
    icon: FaShareAltSquare,
    color: "#e33f70",
    title: "Social Media Integration",
    description:
      "Integrating social media platforms in your business app is helpful as users can check out your digital marketing efforts. Also, they can share your application on their profiles, thereby promoting your business. Rely on our experts to integrate social media portals on your app.",
    iconTwo: FaHandshake,
    titleTwo: " Node JS Community",
    descriptionTwo:
      " Being an open-source framework, there is a huge repository of dynamic tools and modules in the Node Package Manager (NPM). Further, the immense popularity of the platform is making the Node community stronger with more updated tools and modules.",
    iconThree: FaBookReader,
    titleThree: " Security ",
    descriptionThree:
      " In addition to all above features, ASP.NET also offers enhanced application security because of the web applications created with ASP. NET have windows confirmation and configuration. Moreover, the managed code and CLR offer safeguard feature such as role-based security.",
    iconFour: FaMobileAlt,
    titleFour: " NET Mobile App Development ",
    descriptionFour:
      " At TIS, the programmers or developers possess several years of experience and expertise in developing mobile apps. Our developers have created numerous Mobile apps whilst providing a great user experience as well as the solution to many of your business problem.",
    iconFive: FaSwatchbook,
    titleFive: " Widget & Plugin Development",
    descriptionFive:
      "We can craft useful and innovative plugins and widgets for your specific business niche. Our custom plugins will help to extend the functionalities of your web app and enhance user experience. ",
      iconSix: FaHandshake,
      titleSix:"Great Frameworks ",
      descriptionSix:
        " The ionic app development platform has great frameworks such as Angularjs behind it, resulting in robust performance.robust performance",
  },
  {
    icon: FaHandHoldingUsd,
    color: "#e33f70",
    title: "Payment Gateways",
    description:
      "These days, users prefer convenience over anything. Payment gateway integration takes users’ convenience to the next level as they can easily pay through the app and go cashless. This is also helpful for your business as it helps to streamline the revenue process. We can integrate popular payment gateways in your app.",
    iconTwo: FaBattleNet,
    titleTwo: " Caching of Modules",
    descriptionTwo:
      "Node JS enables module caching. When the modules are requested for the first time, they are automatically cached into the application memory thus preventing repeated module code execution for the next time it is called on.. ",
    iconThree: FaDatabase,
    titleThree: " Platforms ",
    descriptionThree:
      "By leveraging ASP.NET, the developers can develop applications for a desktop, a browser or a mobile. Also, ASP.NET is promoted as a language-independent framework. So, development can take place in different compliant languages, including C#, C++, VB.NET, Visual COBOL, IronRuby and more. ",
    iconFour: FaServer,
    titleFour: " .NET Application Migration ",
    descriptionFour:
      " .NET migration has become one of the needs of businesses that we have catered to across a wide variety of scenarios. So, with our experts, you can smoothly migrate your existing web application into ASP.Net.",
    iconFive: FaBattleNet,
    titleFive: " Seek assistance",
    descriptionFive:
      "Seek assistance from our development team to seamlessly migrate your existing web app from one platform to another in a quick turnaround time. ",
      iconSix: FaCogs,
      titleSix:" Easy Maintenance",
      descriptionSix:
        "With common technologies like HTML, CSS & JavaScript within the coding, it’s easy to make changes or maintain the app. ",
  },
  {
    icon: FaUser,
    color: "#e33f70",
    title: "Communicating over BLE & NFC",
    description:
      "NFC or near-field communication works on a two-way principle and enables devices to interact with each other. BLE signals can pass through walls or other barriers that often obstruct Wi-FI and cell signals. Our applications can communicate over BLE and NFC to ensure seamless usability. NFC is specifically useful for the apps that feature indoor navigation.",
    iconTwo: FaServer,
    titleTwo: " Proxy Server",
    descriptionTwo:
      "For server-side applictions those need to pull in data from different sources, you may consider Node JS as a cost saving platform. Node JS can be used to proxy a number of services that have different response times. Thus, you may bypass the cost of setting up proxy infrastructure. ",
    iconThree: FaEdit,
    titleThree: " Architecture ",
    descriptionThree:
      ".NET is usually used for Web Services, that means it is a solution for executing an SOA strategy. So, the applications developed with different programming languages or on different platforms can communicate and transmit data while utilizing the standard Internet protocols.",
    iconFour: FaWindowRestore,
    titleFour: "  CMS Development",
    descriptionFour:
      " Our developers develop the best CMS. So, now you can simplify your content management and deliver your services with the most effective content management software using the latest ASP.Net technology.",
    iconFive: FaBookReader,
    titleFive: "Widget & Plugin Development ",
    descriptionFive:
      "We can craft useful and innovative plugins and widgets for your specific business niche. Our custom plugins will help to extend the functionalities of your web app and enhance user experience. ",
      iconSix: FaHistory,
      titleSix:"Quicker Turn Around Times ",
      descriptionSix:
        " Turn around times for app development or deployment is much quicker and we have delivered great apps in a short span of time as well.",
  },
];

export const androidData = [
  {
    icon: FaPortrait,
    color: "#e33f70",
    title: "Creativity at its Best",
    description:
      "Our developers are efficient in crafting spectacular and engaging app. With the rising competition in the market, only a creative and user-friendly app can stand out from the crowd.",
    iconTwo: FaLaptop,
    titleTwo: " Lesser Coding and Faster Execution",
    descriptionTwo:
      " Development with Angular requires lesser coding resulting in faster develpment and execution.",
    iconThree: FaLaptop,
    titleThree: "Superb UI / UX ",
    descriptionThree:
      " We can build an engaging, intuitive, and visually appealing user interface and ensure that the app offers an unparallel user experience with its ",
  },
  {
    icon: FaSearch,
    color: "#e33f70",
    title: " Customized User Interface",
    description:
      "Instead of using a generic template, we customize the application as per your business niche and requirements. That way, we ensure that the app totally represents your brand.",
    iconTwo: FaBook,
    titleTwo: " Extensive Libraries with Built-in Functionalities",
    descriptionTwo:
      " Availability of rich set of various modular libraries helps to create clean and maintainable codebase.",
    iconThree: FaBookMedical,
    titleThree: " SEO Friendly SPA",
    descriptionThree:
      "Our developers can craft search engine friendly single-page applications (SPA) by leveraging the potential of Angular. ",
  },
  {
    icon: FaLaptopCode,
    color: "#e33f70",
    title: "  Updated with the Latest Technologies",
    description:
      " With the advancement in the tech realm, it’s necessary to keep updated with the trends so as to avoid technical glitches. We stay abreast of the major updates of the Android platform to create a robust app.",
    iconTwo: FaFingerprint,
    titleTwo: "User Experience ",
    descriptionTwo:
      "The two-way data binding between HTML and Angular ensures faster rendering and better user experience. ",
    iconThree: FaMobile,
    titleThree: "Mobile Responsive ",
    descriptionThree:
      " Using this JavaScript MVC framework, our expert Angular developers produce light-weight mobile responsive web applications quite effectively. These are easy to manage .",
  },
  {
    icon: FaThumbsUp,
    color: "#e33f70",
    title: "Scalable Apps",
    description:
      "We offer scalable mobility solutions for start-ups, SMBs and enterprises. That way, there’s a scope of constant growth and feature & functionality extension in your application..",
    iconTwo: FaAsymmetrik,
    titleTwo: "OOPs with Angular 2+ ",
    descriptionTwo:
      " With the inclusion of Object Oriented Paradigm on and from Angular 2, the codebase has become modular and scalable.",
    iconThree: FaNetworkWired,
    titleThree: "Scalable Solution ",
    descriptionThree:
      " Our scalable solutions support your business growth. Explore the full potential of your business and leave the matter of the web application development and ",
  },
];

export const rangeAndroid = [
  {
    icon: FaLaptopCode,
    color: "#e33f70",
    title: "Custom App Development",
    description:
      "Looking to craft spectacular and user-friendly Android apps? We offer you diverse tailor-made solutions that run seamlessly across all Android devices. Our expert team have successfully created numerous customized apps for cross-industry verticals within quick turnaround time. We efficiently address the specific business requisites of small and large enterprises.",
    iconTwo: FaLaptop,
    titleTwo: "Custom Web Applications ",
    descriptionTwo:
      " With our extensive proficiency in MVC, OOP, and core PHP, we craft dynamic PHP web applications for a global clientele. We work with the popular PHP frameworks like Laravel, CakePHP, CodeIgniter, etc. to develop apps for start-ups, SMBs, and enterprises.",
    iconThree: FaCog,
    titleThree: " Smart Architecture",
    descriptionThree:
      " Flutter facilitates agile app development. The Dart programming language in Flutter allows the developers to use Just-in-Time compilation that enhances development workflow.",
  },
  {
    icon: FaRobot,
    color: "#e33f70",
    title: "Stunning UI/UX",
    description:
      "We take pride in possessing extensive experience in UI/UX design. Blending futuristic designs and unparalleled functionality, we create power-packed and reliable applications that render a superb user experience. Hire dedicated Android app developers from us to bring your innovative idea into reality and give your business a new boost.",
    iconTwo: FaDatabase,
    titleTwo: "CMS Development ",
    descriptionTwo:
      "Our PHP developers explore the potential of CMSs to craft custom websites. We possess full-scale expertise in CMSs like Joomla, WordPress, Drupal, Magento, etc. Whether you want a simple blogging website to a more feature-rich one – we can deliver you just that ",
    iconThree: FaSearch,
    titleThree: "Easy Debugging ",
    descriptionThree:
      "Our team has adequate industry-exposure to identify bugs and fix the issues to keep your business application seamless at all times.seamless at all times.seamless at all times.seamless at all times. ",
  },
  {
    icon: FaBookReader,
    color: "#e33f70",
    title: "Feature Extension",
    description:
      "We support your business growth. These days, entrepreneurs seek to launch an MVP (Minimum Viable Product) to achieve a competitive edge and then go on to add features and functionalities as per business requirement. We ensure to offer a scalable mobility solution and can also integrate 3rd party APIs to extend the existing features.",
    iconTwo: FaBookMedical,
    titleTwo: " API Integration",
    descriptionTwo:
      "Improve and extend the functionalities of your web app by integrating our custom 3rd party APIs. With an advanced integration for payment gateway, Twilio, Asterisk for live chat support, we make sure that your application delivers a superb user experience. ",
    iconThree: FaPencilRuler,
    titleThree: "Sprint-driven Development ",
    descriptionThree:
      "This process relies on the repetition of a short development cycle. This ensures a glitch-free app development without requiring much time. ",
  },
  {
    icon: FaWaveSquare,
    color: "#e33f70",
    title: "Deployment",
    description:
      "Our team works with dexterity while executing Google Play Store deployment. We diligently handle the entire app submission process that involves description, asset listing, etc. to enhance its presence in the app store.",
    iconTwo: FaLuggageCart,
    titleTwo: "eCommerce Solution ",
    descriptionTwo:
      " Using the right combination of technologies, we develop standard eCommerce websites. The e-stores bear high-end features and functionalities. Instead of using a generic template, we customize our solution to bring your idea into reality.",
    iconThree: FaEnvelopeOpen,
    titleThree: "Less Coding ",
    descriptionThree:
      "Flutter allows the developers to reuse codes and it has a wide community to support the developers across the world. Whether you are looking to launch an MVP. ",
  },
  {
    icon: FaCog,
    color: "#e33f70",
    title: "App Testing",
    description:
      "We perform rigorous testing to check for bugs and ensure to deliver a glitch-free, market-ready application. Functionality, usability, features, accessibility – we test the entire spectrum of your app. We relentlessly work to ensure that your application is highly functional across multiple devices and delivers a smooth UX.",
    iconTwo: FaUser,
    titleTwo: " Social Networking Solution",
    descriptionTwo:
      "PHP is the right tech-stack for social networking website development. As an efficient PHP development company, we ensure that the site has modern, interactive, and engaging features and keeps your audience hooked to it for a long time. We integrate out-of-the-box attributes into the site. ",
    iconThree: FaTv,
    titleThree: "Custom Widget Development ",
    descriptionThree:
      " Our developers can flexibly craft custom widgets such as gesture detectors, buttons, texts, images, layout columns, checkboxes, etc. We ensure each widget is refined and highly functional.",
  },
  {
    icon: FaMusic,
    color: "#e33f70",
    title: "Support & Maintenance",
    description:
      "Regular maintenance is a part of keeping your app agile and user-friendly at all time. We offer extensive support to ensure your business success. From security review to upgrades, UI changes to bug tracking & fixing, technology tweaks to user-side problem solving – we deliver it all. You focus on your core business development while we optimize your app’s performance.",
    iconTwo: FaDatabase,
    titleTwo: "Ongoing Maintenance ",
    descriptionTwo:
      "Proper maintenance bears huge importance to streamline any business. We are always ready to support your on-demand or ongoing maintenance requirements. You can engage our service as flexibly as you want. ",
    iconThree: FaLaptopCode,
    titleThree: "User-first UI/UX ",
    descriptionThree:
      " Our developers use conditional UI with animations to ensure an engaging user experience. Creating an elegant UI/UX is our speciality.",
  },
];

export const corporateAndroid = [
  {
    icon: FaMobile,
    color: "#e33f70",
    title: "Creativity at its Best",
    description:
      "Our developers are efficient in crafting spectacular and engaging app. With the rising competition in the market, only a creative and user-friendly app can stand out from the crowd.",
    iconTwo: FaHistory,
    titleTwo: " Responsive",
    descriptionTwo:
      " Our PHP based applications are accessible with cross devices including desktops, laptops, tablets, and mobiles.",
    iconThree: FaEnvelopeOpen,
    titleThree: "Custom Node JS development ",
    descriptionThree:
      " Our in-house development team specializes in crafting custom Node JS applications for SMBs and large enterprises. Our developers have an industry exposure in real-time web programming, AJAX and JSON communication, and rich web app development suited to your specific business requirements. ",
    iconFour: FaBook,
    titleFour: "Cross-Platform App Development ",
    descriptionFour:
      "Our advanced hybrid app development services bring your customers an out-of-the-box digital experience. Our developers use quality code libraries, native camera, geolocation, accelerometer and other platform-specific functionalities to craft a seamlessly-performing mobility solution. ",
  },
  {
    icon: FaSearch,
    color: "#e33f70",
    title: "Customized User Interface",
    description:
      "Instead of using a generic template, we customize the application as per your business niche and requirements. That way, we ensure that the app totally represents your brand",
    iconTwo: FaProjectDiagram,
    titleTwo: " Scalable",
    descriptionTwo:
      " We develop scalable web solutions so you can add more features and functionalities as your business grows.",
    iconThree: FaMailBulk,
    titleThree: " API Development",
    descriptionThree:
      " Hire Node JS developers from us to extend the features and functionalities with API development and integration. We can craft JSON-based REST APIs and integrate them into an application. We create custom APIs for payment, shipment, cloud applications, Server-Oriented Architectures (SOA), etc. ",
    iconFour: FaNetworkWired,
    titleFour: " App Re-engineering",
    descriptionFour:
      " App re-engineering is the process of evolving an existing and out of date app with the latest industry trends and technologies. We can re-build your existing apps to a hybrid platform through Flutter. Our customized solution perfectly suits diverse business verticals.",
  },
  {
    icon: FaLaptopCode,
    color: "#e33f70",
    title: "  Updated with the Latest Technologies",
    description:
      "Our developers are efficient in crafting spectacular and engaging app. With the rising competition in the market, only a creative and user-friendly app can stand out from the crowd.",
    iconTwo: FaBookReader,
    titleTwo: " Tailored",
    descriptionTwo:
      "We deliver highly customized web apps that meet your business requisites and offer unparalleled user experience. ",
    iconThree: FaBookMedical,
    titleThree: "Real-time Chat App ",
    descriptionThree:
      " If you are looking to craft social networking apps, our agile development team can be the right partner for you. With broad industry experience, they can help you create social platforms. We provide an end-to-end web solution to help you achieve a competitive edge. ",
    iconFour: FaMobile,
    titleFour: " API & UI Development",
    descriptionFour:
      "With a rich blend of responsive and expressive UI and customized APIs, our specialists can leverage the built-in Material Design of Flutter. We also use various flutter APIs like animation & motion APIs, and iOS-flavour (Cupertino) widgets to enhance the app experience. ",
  },
  {
    icon: FaThumbsUp,
    color: "#e33f70",
    title: "Customized User Interface",
    description:
      "Instead of using a generic template, we customize the application as per your business niche and requirements. That way, we ensure that the app totally represents your brand",
    iconTwo: FaBookDead,
    titleTwo: " Search Engine Friendly",
    descriptionTwo:
      " We adhere to the proper web development guidelines and can optimize the sites to enhance visibility on the SERP.",
    iconThree: FaHandHolding,
    titleThree: " Support & Maintenance",
    descriptionThree:
      " We extend our support to keep your web application highly functional at all times. Feature integration, troubleshooting, upgrades - we offer an extensive maintenance solution to our clients. Our aim is to deliver a top-notch user experience on your web app and we are ready to go an extra mile to ensure that. ",
    iconFour: FaHandHolding,
    titleFour: " Support & Maintenance",
    descriptionFour:
      " We extend our service to offer you a complete support and maintenance service. Software upgrades, security patches integration, database server optimization, performance monitoring, code maintenance, bug fixes, functionality enhancement - we offer it all.",
  },
];

export const rangeIos = [
  {
    icon: FaLaptopMedical,
    color: "#e33f70",
    title: "Advanced Feature Integration",
    description:
      "We can integrate advanced features in your app to help you offer a superb user experience. Stand out from the crowd with an out-of-the-box mobility solution.",
    iconTwo: Fa500Px,
    descriptionTwo:
      "We get to know your brand and extract the gripping stories to share among your social media followers.",
  },
  {
    icon: FaBook,
    color: "#e33f70",
    title: "Flexible Hiring Models",
    description:
      "Out of the wide range of services we provide, eCommerce web development is our specialization.",
    iconTwo: FaBookOpen,
    descriptionTwo:
      "We effectively utilise older blog posts by linking them to the newer ones to maintain the valuable key phrase ranking..",
  },
  {
    icon: FaPoll,
    color: "#e33f70",
    title: "Scalable Solution",
    description:
      "We create a scalable mobile solution that actively supports your business growth. You can launch an MVC and then extend the features and functionalities as your business grows.",
    iconTwo: FaClock,
    descriptionTwo:
      "We make the most of the right opportunities at the right time to develop content that goes viral.",
  },
  {
    icon: FaLaptop,
    color: "#e33f70",
    title: "App Store Deployment",
    description:
      "We’ll take care of the app deployment process in the App Store. The App Store submission process involves assets, listing, descriptions, and many more. Our team will handle everything with dexterity.",
    iconTwo: FaComments,
    descriptionTwo:
      "We join relevant groups to take part in discussions, create engagements and spread message of your brand.",
  },
];

export const iosAndroid = [
  {
    icon: FaRobot,
    color: "#e33f70",
    title: "Creativity at its Best",
    description:
      "Adept with an in-depth knowledge of the native iOS technologies like Objective-C, Swift, etc. we can craft custom iOS apps including modern features. Share your idea with us to transform it into a futuristic, scalable, and secured iOS application.",
  },
  {
    icon: FaLaptopCode,
    color: "#e33f70",
    title: "Upgrade",
    description:
      "Advanced UI design is our USP. Our developers leverage the latest UI toolkit to extend the features and functionalities of your iOS application with custom widget development and integration. It significantly improves UX.",
  },
  {
    icon: FaPoll,
    color: "#e33f70",
    title: "  Updated with the Latest Technologies",
    description:
      "Upgrade your outdated iOS app with us. Our team can skilfully upgrade your app and incorporate new features. We also ensure your iOS app’s compatibility to the latest versions of the iPhone and other iOS devices.",
  },
  {
    icon: FaSearch,
    color: "#e33f70",
    title: "Maintenance",
    description:
      "We support your on-demand and ongoing maintenance requirement and keep your app highly functional at all times. Code maintenance, app and library update, bug fixes, feature extension, security improvement, server performance optimization, UI/UX enhancement – we do it all.",
  },
];

export const hybridData = [
  {
    icon: FaDraftingCompass,
    icontwo: FaHandHoldingUsd,
    title: "Design",
    description:
      "User experience is always our priority. We ensure a seamless UI/UX design when developing the App.",
    desc: "The development costs are quite low",
  },
  {
    icon: FaLaptopCode,
    icontwo: FaPencilRuler,
    title: "Development",
    description:
      "Using a diverse range of codes and frameworks, we give life to the designs and create a robust Hybrid App for the users.",
    desc: "Impressive UI / UX",
  },
  {
    icon: FaFileAlt,
    icontwo: FaLaptopCode,
    title: "Testing",
    description:
      "After development, we hire experts in the technological field to test your App and give his/her feedback as a user. Accordingly, we fix bugs, enhance performance and tweak designs if required.",
    desc: "High performance and scalability is ensured",
  },
  {
    icon: FaCog,
    icontwo: FaClock,
    title: "Maintenance",
    description:
      "After everything is done and the app is up, we stand by you at all times starting from its launch to the day you call us again to upscale it. We closely monitor the App and upgrade its features as and when you need.",
    desc: "A single-code-fits-all solution that reduces development time",
  },
];
export const cardData = [
  {
    icon: FaBook,
    title: "Prototype Testing",
  },
  {
    icon: FaGlobe,
    title: "Cross-browser Testing",
  },
  {
    icon: FaCloudSunRain,
    title: "Cloud-based Testing",
  },
  {
    icon: FaCog,
    title: "Test Automation",
  },
  {
    icon: FaTv,
    title: "Web Application Testing",
  },
  {
    icon: FaLaptopCode,
    title: "Desktop/Mobile App Testing",
  },
  {
    icon: FaUser,
    title: "QA Consulting",
  },
  {
    icon: FaFileAlt,
    title: "Functionality & Usability Testing",
  },
  {
    icon: FaHandHolding,
    title: "Performance Testing",
  },
  {
    icon: FaSearch,
    title: "Integrated Systems Testing",
  },
  {
    icon: FaTabletAlt,
    title: "Device Compatibility",
  },
  {
    icon: FaHandHoldingUsd,
    title: "Security Testing",
  },
];
