import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Components/Assets/secondary-logo.webp";
import website from "../../Components/Assets/web-development.webp";
import mobile from "../../Components/Assets/mobile-PPC-Management.webp";
import social from "../../Components/Assets/social-media-marketing.webp";
import block from "../Assets/blockchain.webp";
import ai from "../Assets/ai-ml.webp";
import cms from "../../Components/Assets/cms_1.webp";
import { FaEnvelope, FaPhoneAlt, FaSkype } from "react-icons/fa";
import Login from "../Forms/Login";

const Navbartwo = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleButtonClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setIsPopupOpen(true);
  };

 


  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSecond, setIsSecond] = useState(false);
  const [isThird, setIsThird] = useState(false);
  const [mobileNav, setMobileNav] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpen9, setDropdownOpen9] = useState(false);
  const [isDropdownOpen3, setDropdownOpen3] = useState(false);
  const [isInnerDropdownOpen, setInnerDropdownOpen] = useState(false); // New state for inner dropdown
  const [isInnerDropdownOpen2, setInnerDropdownOpen2] = useState(false); // New state for inner dropdown
  const [isInnerDropdownOpen3, setInnerDropdownOpen3] = useState(false); // New state for inner dropdown
  const [isInnerDropdownOpen4, setInnerDropdownOpen4] = useState(false); // New state for inner dropdown
  const [isInnerDropdownOpen5, setInnerDropdownOpen5] = useState(false); // New state for inner dropdown
  const [isInnerDropdownOpen6, setInnerDropdownOpen6] = useState(false); // New state for inner dropdown
  const [isInnerDropdownOpen7, setInnerDropdownOpen7] = useState(false); // New state for inner dropdown
  const [isInnerDropdownOpen8, setInnerDropdownOpen8] = useState(false); // New state for inner dropdown

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 867) {
        setMobileNav(false);
      } else {
        setMobileNav(true);
      }
    };

    // Check the width when the component mounts
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    console.log(isMobileMenuOpen);
    setDropdownOpen(false);
    setDropdownOpen9(false);
    setInnerDropdownOpen2(false);
    setInnerDropdownOpen(false);
    setInnerDropdownOpen3(false);
    setInnerDropdownOpen4(false);
    setInnerDropdownOpen5(false);
    setInnerDropdownOpen6(false);
    setInnerDropdownOpen7(false);
    setInnerDropdownOpen8(false);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
    setIsSecond(false);
    setIsThird(false);
  };
  const handleMouseSecond = () => {
    setIsSecond(true);
    setIsOpen(false);
    setIsThird(false);
  };

  const handleMouseLeaveSecond = () => {
    setIsSecond(false);
  };
  const handleMouseThird = () => {
    setIsSecond(false);
    setIsOpen(false);
    setIsThird(true);
  };

  const handleMouseLeaveThird = () => {
    setIsThird(false);
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
  };
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const isActive = (path) => {
    return location.pathname === path
      ? "bg-gradient-to-r from-teal-400 to-indigo-700 text-transparent bg-clip-text"
      : "";
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdownSecond = () => {
    setDropdownOpen9(!isDropdownOpen9);
  };
  const toggleDropdownThird = () => {
    setDropdownOpen3(!isDropdownOpen3);
  };

  return (
    <>
      {mobileNav ? (
        <nav
          className={`bg-white  border-gray-200 nav-width  rounded absolute z-10  ${
            isScrolled ? "sticky-nav coustom_margin scrolled" : "mx-4 sm:mx-8 md:mx-12 lg:mx-20 mt-[.75rem]"
          }`}
        >
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between relative z-50 mx-auto px-3 ">
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src={logo} className="h-auto nav-logo" alt="Flowbite Logo" />
            </Link>
            <button
              onClick={toggleNav}
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 md:hidden"
              aria-controls="navbar-default"
              aria-expanded={isNavOpen ? "true" : "false"}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>

            <div
              className={`${
                isNavOpen ? "block" : "hidden"
              } w-full md:block md:w-auto`}
              id="navbar-default"
            >
              <ul className="navbar-main font-medium text-base flex flex-col pl-4 py-4 pr-10 md:p-0 mt-1  md:flex-row md:space-x-8 rtl:space-x-reverse  ">
                <li>
                  <Link
                    to="/"
                    
                    className={`block py-2 px-3  md:hover:bg-transparent md:border-0 hover:bg-gradient-to-r hover:from-teal-400 hover:to-indigo-700 hover:text-transparent hover:bg-clip-text md:p-0 dark:text-black ${isActive(
                      "/"
                    )}`}
                    aria-current="page"
                  >
                    Home
                  </Link>
                </li>
                <li onMouseEnter={handleMouseEnter}>
                  <Link
                    to="/about"
                    className={` block py-2 px-3 icon-change md:hover:bg-transparent md:border-0 hover:bg-gradient-to-r hover:from-teal-400 hover:to-indigo-700 hover:text-transparent hover:bg-clip-text md:p-0 dark:text-black ${isActive(
                      "/about"
                    )}`}
                  >
                    About
                    {isOpen && (
                      <ul
                        onMouseLeave={handleMouseLeave}
                        className="absolute top-8 left-0 mt-2 w-48 bg-white border-t-2 border-teal-400 to-indigo-700 shadow-lg "
                      >
                        <li>
                          <Link
                            to="/why-us"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100  "
                          >
                            Why Us
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/our-team"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                          >
                            Our Team
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/mission"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                          >
                            Mission & Vision
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/our-portfolio"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                          >
                            Our Portfolio
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/price"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                          >
                            Our Pricing Package
                          </Link>
                        </li>
                      </ul>
                    )}
                  </Link>
                </li>
                <li onMouseEnter={handleMouseSecond}>
                  <Link
                    to="/service"
                    className={`block py-2 px-3 icon-change md:hover:bg-transparent md:border-0 hover:bg-gradient-to-r hover:from-teal-400 hover:to-indigo-700 hover:text-transparent hover:bg-clip-text md:p-0 dark:text-black ${isActive(
                      "/service"
                    )}`}
                  >
                    Services
                  </Link>
                </li>
                <li onMouseEnter={handleMouseThird}>
                  <Link
                    to="/trending-technology"
                    className={`block py-2 px-3 icon-change md:hover:bg-transparent md:border-0 hover:bg-gradient-to-r hover:from-teal-400 hover:to-indigo-700 hover:text-transparent hover:bg-clip-text md:p-0 dark:text-black ${isActive(
                      "/trending-technology"
                    )}`}
                  >
                    Technologies
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog"
                    className={`block py-2 px-3  md:hover:bg-transparent md:border-0 hover:bg-gradient-to-r hover:from-teal-400 hover:to-indigo-700 hover:text-transparent hover:bg-clip-text md:p-0 dark:text-black ${isActive(
                      "/blog"
                    )}`}
                  >
                    Blogs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className={`block py-2 px-3 md:hover:bg-transparent md:border-0 hover:bg-gradient-to-r hover:from-teal-400 hover:to-indigo-700 hover:text-transparent hover:bg-clip-text md:p-0 dark:text-black ${isActive(
                      "/contact"
                    )}`}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <button
                className="flex items-center bg-dark-200  w-full justify-center text-white py-[15px] sm:py-[12px] px-4 sm:px-8 font-heading transition duration-400 ease-in-out rounded-full text-xs sm:text-xs md:text-sm lg:text-base focus:outline-none hover:bg-gray-300 bg-gradient-to-r from-teal-400 to-indigo-700"
                onClick={handleButtonClick}
              >
                Request A Quote
              </button>

              {isPopupOpen && (
                <div className="fixed inset-24 top-[12%]">
                  
                  <Login handleClosePopup={handleClosePopup} />
                </div>
              )}
            </div>
          </div>
          {isSecond && (
            <>
              <div
                className="flex gap-3 justify-around font-semibold border-t border-teal-400 to-indigo-700 px-5 pb-5 bg-white absolute top-[75px] left-0 w-full"
                onMouseLeave={handleMouseLeaveSecond}
              >
                <ul className="  mt-2  bg-white w-84 ">
                  <li className="border-b">
                    <Link
                      to="/website-design"
                      className="flex gap-3 px-4 py-4 font-semibold text-gray-800 hover:bg-gray-100 "
                    >
                      Website Design{" "}
                      <img src={website} className="w-7 " alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ecommerce-website-development"
                      className="block px-4 py-3 text-gray-800 hover:bg-gray-100 "
                    >
                      eCommerce Website
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/web-app-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Web Application Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cms-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      CMS Web Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/business-developement"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Small Business Website
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/web-app-developemnt-corporate"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Corporate Website
                    </Link>
                  </li>
                </ul>
                <ul className="  mt-2  bg-white w-84 ">
                  <li className="border-b">
                    <Link
                      to="/application-developement"
                      className="flex gap-3 py-[15px] px-[10px] font-semibold text-gray-800 hover:bg-gray-100 "
                    >
                      Mobile App Development{" "}
                      <img src={mobile} className="w-7 " alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/android-application-development"
                      className="block px-4 py-3 text-gray-800 hover:bg-gray-100 "
                    >
                      Android App Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ios-application-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      IOS App Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/hybrid-application-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Hybrid App Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/mobile-application-testing"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Mobile App Testing
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/quality-assurance"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Quality  Assurance
                    </Link>
                  </li>
                </ul>

                <ul className="  mt-2  bg-white w-84 ">
                  <li className="border-b">
                    <Link
                      to="/digital-marketing-service"
                      className="flex gap-3 py-[15px] px-[10px]  font-semibold text-gray-800 hover:bg-gray-100 "
                    >
                      Digital Marketing Service{" "}
                      <img src={social} className="w-7 " alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/search-engine-optimization"
                      className="block px-4 py-3 text-gray-800 hover:bg-gray-100 "
                    >
                      Seo Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/pay-per-click-service"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      PPC Management
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/social-media-marketing-service"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Social Media Marketing
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/local-search-engine-optimization"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Local Seo Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/content-marketing-service"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Content Marketing Services
                    </Link>
                  </li>
                </ul>
                <ul className=" top-8 left-0 mt-2 w-84 bg-white font-semibold  ">
                  <li className="border-b">
                    <Link
                      to="/graphic-design-services"
                      className="flex gap-3 px-4 py-4 font-semibold text-gray-800 hover:bg-gray-100 "
                    >
                      Design & Branding
                      <img src={cms} className="w-7" alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/logo-design-services"
                      className="block px-4 py-3 text-gray-800 hover:bg-gray-100 "
                    >
                      Logo Design
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/corporate-stationery-design"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Corporate Identity Design
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/brochure-design-service"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Brochure Design
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/animated-services"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Animated Videos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/creative-services"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Creative Agency
                    </Link>
                  </li>
                </ul>
              </div>
            </>
         )} 
          {isThird && (
            <>
              <div
                className="flex max-sm:block gap-3 justify-around font-semibold border-t border-teal-400 to-indigo-700 px-5 pb-5 bg-white absolute top-[75px] left-0 w-full"
                onMouseLeave={handleMouseLeaveThird}
              >
                <ul className="  mt-2  bg-white w-84 ">
                  <li className="border-b">
                    <Link
                      to="#"
                      className="flex gap-3 px-4 py-4  text-gray-800 font-semibold hover:bg-gray-100 "
                    >
                      Web Technology
                      <img src={website} className="w-7 " alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/php-laravel-codeigniter-services"
                      className="block px-4 py-3 text-gray-800 hover:bg-gray-100 "
                    >
                      PHP-Laravel, Codeigniter
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/reactjs-vuejs-nodejs-development-services"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      React js, Vue js, Node js
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/asp-dot-net-service"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Asp .net
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/python-and-django-service"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Python-Django, flask
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/angularjs-development-service"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Angular
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/wordpress-and-shopify-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      WP, Shopify, Joomla, Magento
                    </Link>
                  </li>
                </ul>
                <ul className="  mt-2  bg-white w-84 ">
                  <li className="border-b">
                    <Link
                      to="#"
                      className="flex gap-2 px-4 py-4 text-gray-800 font-semibold hover:bg-gray-100 "
                    >
                      Mobile Application{" "}
                      <img src={mobile} className="w-7 " alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/swift-app-development"
                      className="block px-4 py-3 text-gray-800 hover:bg-gray-100 "
                    >
                      Swift
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/react-native-application-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      React native
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/flutter-application-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Flutter
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ionic-application-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Ionic
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/java-application-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Java
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/unity-game-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      UNITY - 2D, 3D
                    </Link>
                  </li>
                </ul>

                <ul className="  mt-2  bg-white w-84 ">
                  <li className="border-b">
                    <Link
                      to="#"
                      className="flex gap-3 px-4 py-4 font-semibold  text-gray-800 hover:bg-gray-100 "
                    >
                      AI / ML <img src={ai} className="w-7 " alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/python-numpy-development"
                      className="block px-4 py-3 text-gray-800 hover:bg-gray-100 "
                    >
                      Python-NumPy, Pandas
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ruby-and-pybrain-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Ruby, Pybrain
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/java-application-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Java
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/julia-developement-service"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Julia
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/lisp-online-store-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Lisp
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/haskell-ai-and-ml-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Haskell
                    </Link>
                  </li>
                </ul>
                <ul className=" top-8 left-0 mt-2 w-84 bg-white   ">
                  <li className="border-b">
                    <Link
                      to="#"
                      className="flex gap-3 px-4 py-4 font-semibold text-gray-800 hover:bg-gray-100 "
                    >
                      Blockchain
                      <img src={block} className="w-7" alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/c-plus-plus-blockchain-development"
                      className="block px-4 py-3 text-gray-800 hover:bg-gray-100 "
                    >
                      C++
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/java-application-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Java
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/customer-polls-blockchain"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Customer Polls
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/solidity-blockchain-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Solidity
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/php-laravel-codeigniter-services"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      PHP
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/python-blockchain-development"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 "
                    >
                      Python
                    </Link>
                  </li>
                </ul>
              </div>
            </>
          )}
        </nav>
      ) : (
        <>
  
        <nav className={`bg-white border-gray-200 nav-width rounded absolute z-4 mx-4 sm:mx-8 md:mx-12 lg:mx-20 mt-1 ${isScrolled ? 'sticky-nav  coustom_margin' : ''}  `}  >
          <div className=" mx-auto flex justify-between items-center p-[0.2rem]">
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img
                src={logo}
                className="h-auto w-40 nav-logo"
                alt="Flowbite Logo"
              />
            </Link>
            <ul className="hidden  space-x-4">
              <li>
                <Link to="#" x className="text-gray-300 hover:text-white">
                  Home
                </Link>
              </li>
              <li>
                <Link to="#" className="text-gray-300 hover:text-white">
                  About
                </Link>
              </li>
              <li>
                <Link to="#" className="text-gray-300 hover:text-white">
                  Services
                </Link>
              </li>
              <li>
                <Link to="#" className="text-gray-300 hover:text-white">
                  Contact
                </Link>
              </li>
            </ul>
            <div className=" ">
              <button
                onClick={toggleMobileMenu}
                className="text-black focus:outline-none"
                aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
              >
                <svg
                  className={`w-8 h-6 mt-[8px] ${
                    isMobileMenuOpen ? "cross-animation" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transition: "transform 0.3s ease" }} // Add transition property here
                >
                  {isMobileMenuOpen ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    ></path>
                  )}
                </svg>
              </button>
            </div>
          </div>
          <div
            className={`fixed z-50 top-0 left-0 h-full bg-gray-800 transition-transform transform ${
              isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
            }  w-84`}
          >
            <div className=" flex bg-white p-2  justify-between items-center">
              <Link to="/">
                {" "}
                <img src={logo} alt="" className="w-40" />
              </Link>
              <button
                onClick={toggleMobileMenu}
                className="text-black   focus:outline-none"
              >
                <svg
                  className="w-8 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>

            <ul className="mobile-border mt-4 relative">
              <li>
                <Link to="/" onClick={toggleMobileMenu} className="text-gray-300  hover:text-white block">
                  Home
                </Link>
              </li>
              <li className="relative">

                <div  className="text-gray-300 flex items-center hover:text-white relative">

               
                <Link
                  to="/about"
                  onClick={toggleMobileMenu}
                >
                  About
                  </Link>
                  <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
                    <svg
                      className={`w-8 h-8 fill-current transition-transform ${
                        isDropdownOpen3 ? "rotate-180" : ""
                      }`}
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => toggleDropdownThird(!isDropdownOpen3)}
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
               
                </div>

                <div
                  className={`transition-all duration-300 ${
                    isDropdownOpen3
                      ? "max-h-96 opacity-100"
                      : "max-h-0 opacity-0"
                  }  ease-in-out overflow-scroll mobile-bor`}
                >
                  {/* Dropdown content */}

                  <Link
                    to="why-us"
                    onClick={toggleMobileMenu}
                    className="text-gray-300 hover:text-white block py-2 px-2   "
                  >
                    Why Us
                  </Link>
                  <Link
                    to="our-team"
                    onClick={toggleMobileMenu}
                    className="text-gray-300 hover:text-white block py-2 px-2  "
                  >
                    Our Team
                  </Link>
                  <Link
                    to="mission"
                    onClick={toggleMobileMenu}
                    className="text-gray-300 hover:text-white block py-2 px-2   "
                  >
                    Mission & Vision
                  </Link>
                  <Link
                    to="our-portfolio"
                    onClick={toggleMobileMenu}
                    className="text-gray-300 hover:text-white block py-2 px-2   "
                  >
                    Our Portfolio
                  </Link>
                  <Link
                    to="price"
                    className="text-gray-300 hover:text-white block py-2 px-2   "
                  >
                    Our Pricing Package
                  </Link>
                </div>
              </li>


              <li className="relative">
  <Link
    to="service"
    onClick={toggleMobileMenu}
    className="text-gray-300 flex items-center hover:text-white relative"
  >
    Services
    <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
      <svg
        className={`w-8 h-8 fill-current transition-transform ${
          isDropdownOpen ? "rotate-180" : ""
        }`}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleDropdown(!isDropdownOpen);
        }}
      >
        <path
          fillRule="evenodd"
          d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  </Link>

  <div
    className={`transition-all duration-300 ${
      isDropdownOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
    } ease-in-out overflow-scroll`}
  >
    {/* Dropdown content */}
    <div className="relative">
      <Link
       onClick={toggleMobileMenu}
        to="/website-design"
        className="text-gray-300 flex p-2 items-center hover:text-white relative"
      >
        Website Design
        <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
          <svg
            className={`w-8 h-8 fill-current border-1 transition-transform ${
              isInnerDropdownOpen ? "rotate-180" : ""
            }`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setInnerDropdownOpen(!isInnerDropdownOpen);
            }}
          >
            <path
              fillRule="evenodd"
              d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </Link>

      <div
        className={`transition-all duration-300 ${
          isInnerDropdownOpen ? "opacity-100 " : "max-h-0 opacity-0"
        } ease-in-out overflow-scroll mobile-bor top-full left-0 w-full bg-gray-800`}
      >
        <Link
          to="/ecommerce-website-development"
          onClick={toggleMobileMenu}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          eCommerce Website
        </Link>
        <Link
          to="web-app-development"
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Web Application Development
        </Link>
        <Link
          to="/cms-development"
          onClick={toggleMobileMenu}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          CMS Web Development
        </Link>
        <Link
          to="/business-developement"
          onClick={toggleMobileMenu}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Small Business Website
        </Link>
        <Link
          to="/corporate-stationery-design"
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Corporate Website
        </Link>
      </div>
    </div>

    <div className="relative">
      <Link
        to="/mobile-application-testing"
        onClick={toggleMobileMenu}
        className="text-gray-300 flex p-2 items-center hover:text-white relative"
      >
        Mobile App Development
        <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
          <svg
            className={`w-8 h-8 fill-current border-1 transition-transform ${
              isInnerDropdownOpen2 ? "rotate-180" : ""
            }`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setInnerDropdownOpen2(!isInnerDropdownOpen2);
            }}
          >
            <path
              fillRule="evenodd"
              d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </Link>

      <div
        className={`transition-all duration-300 ${
          isInnerDropdownOpen2 ? "opacity-100 " : "max-h-0 opacity-0"
        } mobile-bor ease-in-out overflow-scroll top-full left-0 w-full bg-gray-800`}
      >
        <Link
          to="/android-application-development"
    
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Android App Development
        </Link>
        <Link
          to="/ios-application-development"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          IOS App Development
        </Link>
        <Link
          to="/hybrid-application-development"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Hybrid App Development
        </Link>
        <Link
          to="/application-developement"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Mobile-App-Testing
        </Link>
        <Link
          to="/quality-assurance"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Quality -Assurance
        </Link>
      </div>
    </div>

    <div className="relative">
      <Link
        to="/digital-marketing-service"
        onClick={() => setIsMobileMenuOpen(false)}
        className="text-gray-300 flex p-2 items-center hover:text-white relative"
      >
        Digital Marketing Service
        <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
          <svg
            className={`w-8 h-8 fill-current border-1 transition-transform ${
              isInnerDropdownOpen3 ? "rotate-180" : ""
            }`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setInnerDropdownOpen3(!isInnerDropdownOpen3);
            }}
          >
            <path
              fillRule="evenodd"
              d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </Link>

      <div
        className={`transition-all duration-300  ${
          isInnerDropdownOpen3 ? "opacity-100 " : "max-h-0 opacity-0"
        } mobile-bor ease-in-out overflow-scroll top-full left-0 w-full bg-gray-800`}
      >
        <Link
          to="/search-engine-optimization"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Seo Services
        </Link>
        <Link
          to="/pay-per-click-service"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          PPC Management
        </Link>
        <Link
          to="/social-media-marketing-service"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Social Media Marketing
        </Link>
        <Link
          to="/local-search-engine-optimization"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Local Seo Services
        </Link>
        <Link
          to="/content-marketing-service"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Content Marketing Services
        </Link>
      </div>
    </div>
    <div className="relative">
      <Link
        to="/graphic-design-services"
        onClick={() => setIsMobileMenuOpen(false)}
        className="text-gray-300 flex p-2 items-center hover:text-white relative"
      >
        Design & Branding
        <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
          <svg
            className={`w-8 h-8 fill-current border-1 transition-transform ${
              isInnerDropdownOpen4 ? "rotate-180" : ""
            }`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setInnerDropdownOpen4(!isInnerDropdownOpen4);
            }}
          >
            <path
              fillRule="evenodd"
              d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </Link>

      <div
        className={`transition-all duration-300 ${
          isInnerDropdownOpen4 ? "opacity-100 " : "max-h-0 opacity-0"
        } mobile-bor ease-in-out overflow-scroll top-full left-0 w-full bg-gray-800`}
      >
        <Link
          to="/logo-design-services"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Logo Design
        </Link>
        <Link
          to="/corporate-stationery-design"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Corporate Identity Design
        </Link>
        <Link
          to="/brochure-design-service"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Brochure Design
        </Link>
        <Link
          to="/animated-services"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Animated Videos
        </Link>
        <Link
          to="/creative-services"
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-300 hover:text-white block py-2 px-2"
        >
          Creative Agency
        </Link>
      </div>
    </div>

    {/* End of Dropdown content */}
  </div>
</li>



            
              <li className="relative">
                <Link
                  to="trending-technology"
                  onClick={toggleMobileMenu}
                  className="text-gray-300 flex items-center hover:text-white relative"
                >
                  Technology
                  <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
                    <svg
                      className={`w-8 h-8 fill-current transition-transform ${
                        isDropdownOpen9 ? "rotate-180" : ""
                      }`}
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleDropdownSecond(!isDropdownOpen9)
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
                        clipRule="evenodd"
                      />

                    </svg>
                   
                  </span>
                </Link>

                <div
                  className={`transition-all duration-300 ${
                    isDropdownOpen9
                      ? "max-h-96 opacity-100"
                      : "max-h-0 opacity-0"
                  } rounded ease-in-out overflow-scroll`}
                >
                  {/* Dropdown content */}
                  <div className="relative">
                    <Link
                      to="#"
                      className="text-gray-300 flex p-2 items-center hover:text-white relative"
                    >
                      Web Technology
                      <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
                        <svg
                          className={`w-8 h-8 fill-current border-1 transition-transform ${
                            isInnerDropdownOpen5 ? "rotate-180" : ""
                          }`}
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={(e) => {
                            e.preventDefault();
                            setInnerDropdownOpen5(!isInnerDropdownOpen5);
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </Link>

                    <div
                      className={`transition-all duration-300 ${
                        isInnerDropdownOpen5
                          ? "opacity-100 "
                          : "max-h-0 opacity-0"
                      } mobile-bor ease-in-out overflow-scroll  top-full left-0 w-full bg-gray-800`}
                    >
                      <Link
                        to="/php-laravel-codeigniter-services"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        PHP-Laravel, Codeigniter
                      </Link>
                      <Link
                        to="/reactjs-vuejs-nodejs-development-services"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2  "
                      >
                        React js, Vue js, Node js
                      </Link>
                      <Link
                        to="/asp-dot-net-service"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Asp .net
                      </Link>
                      <Link
                        to="/python-and-django-service"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Python-Django, flask
                      </Link>
                      <Link
                        to="/angularjs-development-service"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Angular
                      </Link>
                      <Link
                        to="/wordpress-and-shopify-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        WP, Shopify, Joomla, Magento
                      </Link>
                    </div>
                  </div>

                  <div className="relative">
                    <Link
                      to="/mobile-application-testing"
                      onClick={() => setIsMobileMenuOpen(false)}
                      className="text-gray-300 flex p-2 items-center hover:text-white relative"
                    >
                      Mobile Application
                      <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
                        <svg
                          className={`w-8 h-8 fill-current border-1 transition-transform ${
                            isInnerDropdownOpen6 ? "rotate-180" : ""
                          }`}
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={(e) => {
                            e.preventDefault();
                            setInnerDropdownOpen6(!isInnerDropdownOpen6);
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </Link>

                    <div
                      className={`transition-all duration-300 ${
                        isInnerDropdownOpen6
                          ? "opacity-100 "
                          : "max-h-0 opacity-0"
                      } mobile-bor ease-in-out overflow-scroll  top-full left-0 w-full bg-gray-800`}
                    >
                      <Link
                        to="/swift-app-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Swift
                      </Link>
                      <Link
                        to="/react-native-application-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2  "
                      >
                        React native
                      </Link>
                      <Link
                        to="/flutter-application-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Flutter
                      </Link>
                      <Link
                        to="/ionic-application-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Ionic
                      </Link>
                      <Link
                        to="/java-application-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Java
                      </Link>
                      <Link
                        to="/unity-game-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        UNITY - 2D, 3D
                      </Link>
                    </div>
                  </div>

                  <div className="relative">
                    <Link
                      to="#"
                      className="text-gray-300 flex p-2 items-center hover:text-white relative"
                    >
                      AI / ML
                      <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
                        <svg
                          className={`w-8 h-8 fill-current border-1 transition-transform ${
                            isInnerDropdownOpen7 ? "rotate-180" : ""
                          }`}
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={(e) => {
                            e.preventDefault();
                            setInnerDropdownOpen7(!isInnerDropdownOpen7);
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </Link>

                    <div
                      className={`transition-all duration-300 ${
                        isInnerDropdownOpen7
                          ? "opacity-100 "
                          : "max-h-0 opacity-0"
                      } mobile-bor ease-in-out overflow-scroll  top-full left-0 w-full bg-gray-800`}
                    >
                      <Link
                        to="/python-numpy-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Python-NumPy, Pandas
                      </Link>
                      <Link
                        to="/ruby-and-pybrain-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2  "
                      >
                        Ruby, Pybrain
                      </Link>
                      <Link
                        to="/java-application-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Java
                      </Link>
                      <Link
                        to="/julia-developement-service"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Julia
                      </Link>
                      <Link
                        to="/lisp-online-store-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Lisp
                      </Link>
                      <Link
                        to="/haskell-ai-and-ml-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Haskell
                      </Link>
                    </div>
                  </div>

                  <div className="relative">
                    <Link
                      to="#"
                      className="text-gray-300 flex p-2 items-center hover:text-white relative"
                    >
                      Blockchain
                      <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
                        <svg
                          className={`w-8 h-8 fill-current border-1 transition-transform ${
                            isInnerDropdownOpen8 ? "rotate-180" : ""
                          }`}
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={(e) => {
                            e.preventDefault();
                            setInnerDropdownOpen8(!isInnerDropdownOpen8);
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12l4-4-1.41-1.41L10 9.17 7.41 6.59 6 8l4 4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </Link>

                    <div
                      className={`transition-all duration-300 ${
                        isInnerDropdownOpen8
                          ? "opacity-100 "
                          : "max-h-0 opacity-0"
                      } mobile-bor ease-in-out overflow-scroll  top-full left-0 w-full bg-gray-800`}
                    >
                      <Link
                        to="/c-plus-plus-blockchain-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        C++
                      </Link>
                      <Link
                        to="/java-application-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2  "
                      >
                        Java
                      </Link>
                      <Link
                        to="/customer-polls-blockchain"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Customer Polls
                      </Link>
                      <Link
                        to="/solidity-blockchain-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Solidity
                      </Link>
                      <Link
                        to="/php-laravel-codeigniter-services"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        PHP
                      </Link>
                      <Link
                        to="/python-blockchain-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="text-gray-300 hover:text-white block py-2 px-2   "
                      >
                        Python
                      </Link>
                    </div>
                  </div>

                  {/* End of Dropdown content */}
                </div>
              </li>

              <li>
                <Link
                  to="blog"
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="text-gray-300 hover:text-white block"
                >
                 Blog
                </Link>
              </li>


              <li>
                <Link
                  to="contact"
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="text-gray-300 hover:text-white block"
                >
                  Contact
                </Link>
              </li>
              <div className="w-full px-4">
                <button
                  className="flex items-center bg-dark-200 mt-5 w-full justify-center text-white py-[15px] sm:py-[12px] px-4 sm:px-8 font-heading transition duration-400 ease-in-out rounded-full text-xs sm:text-xs md:text-sm lg:text-base focus:outline-none hover:bg-gray-300 bg-gradient-to-r from-teal-400 to-indigo-700"
                  onClick={handleButtonClick}
                >
                  Request A Quote
                </button>
              </div>
            </ul>

            <div className="absolute bottom-5 flex justify-evenly w-full  text-white">
              <Link to="tel:+91-7056937000">
                <FaPhoneAlt className="text-2xl" />
              </Link>
              <Link to="mailto:info@futuretouch.in">
                <FaEnvelope className="text-2xl" />
              </Link>
              <Link to="skype:futuretouch">
                <FaSkype className="text-2xl" />
              </Link>
            </div>
          </div>
        </nav>
        {isPopupOpen && (
          <div className="absolute -top-20 max-md:-top-20 max-lg:-top-10 z-50 max-md:z-50 max-md:w-full w-full">
            
            <Login handleClosePopup={handleClosePopup}  />
          </div>
        )}
        </>
      )}
    </>
  );
};

export default Navbartwo;
