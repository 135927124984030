import React, { useState } from "react";
import {
  FaEnvelope,
  FaSkype,
  FaInstagram,
  FaPhoneAlt,
  FaCaretDown,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
  FaGithub,
} from "react-icons/fa";
import "./Navbar.css";
import { Link } from "react-router-dom";
import country from "../Assets/country.webp";
import flag from "../Assets/flag.webp";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };
  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };
  return (
    <>
      <header className="w-full p-2 lg:px-5  bg-gradient-to-r from-teal-400 to-indigo-700  text-white grid grid-cols-1 md:grid-cols-2  gap-3 sm:gap-2 ">

          

          <div className="hidden lg:block">
          <div className="header-top-call flex  sm:justify-start justify-around lg:justify-start font-bold sm:text-sm ">


<div className="hidden lg:block">
  <p className="flex items-center   text-lg max-sm:text-base  pr-2 ">

    <FaEnvelope className="mr-3" />
    <p> info@futuretouch.in</p>
  </p>
</div>

<div>

</div>
<p className="hidden lg:block">|</p>

<Link to="skype:live:.cid.313b26920df66baf" className="flex text-lg max-sm:text-base items-center ml-3">

  <FaSkype className="mr-2 hidden lg:block" />
  <p className="hidden lg:block">  futuretouch</p>
</Link>
</div>
          </div>
       


        <div className="header-med-email flex items-center flex-row-reverse justify-around lg:justify-end gap-5 text-end lg:flex-row">
          <div className="relative">
            <button
              onMouseEnter={handleMouseEnter}
              onClick={() => toggleVisibility()}
              className="flex items-center focus:outline-none"
            >

              <FaPhoneAlt className="h-4 w-5" />
              <FaCaretDown className="h-5 ml-1 " />
              <span className="ml-2 text-bold"  >
                Support
              </span>
            </button>
            {isOpen && (
              <div

                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="absolute left-[-159%] lg:left-0  lg:right-0 mt-2 w-72 py-3 text-start bg-white rounded-md shadow-lg index-main"
              >
                <Link
                  to="#"
                  className="block px-4 pb-2 text-xl text-gray-700 rounded-md font-bold "
                >
                  Future IT Touch Contacts
                </Link>
                <Link
                  to="tel:+91-7056937000"
                  className="block px-4 py-2 text-sm text-gray-700  bg-[#e4e8ff]"
                >
                  <h5 className="block  pb-2 text-base text-[#5f637d] font-bold  ">
                    FOR HR DEPARTMENT
                  </h5>
                  <a
                    href="tel:+91-7056937000"
                    className="block text-gray-700 font-bold text-base"
                  >
                    <li className="flex items-center gap-3">
                      <img
                        src={country}
                        className="w-8 h-8 object-cover"
                        alt=""
                      />
                      +91-7056937000
                    </li>
                  </a>

                </Link>
                <h5 className="block px-4 py-2 text-base text-[#5f637d] font-bold ">
                  FOR SALES DEPARTMENT
                </h5>
                <ul>
                  <Link
                    to="tel:+91-7056997000"
                    className="block px-4 py-2 text-gray-700 font-bold text-base"
                  >
                    <li className="flex items-center gap-3">
                      <img
                        src={country}
                        className="w-8 h-8 object-cover"
                        alt=""
                      />
                      +91-7056997000
                    </li>
                  </Link>
                  <Link
                    to="tel:+91-7056937000"
                    className="block px-4 py-2 text-gray-700 font-bold text-base"
                  >
                    <li className="flex items-center gap-3">
                      <img
                        src={flag}
                        className="w-7 pl-1 h-5  object-cover"
                        alt=""
                      />
                      +91-7056937000
                    </li>
                  </Link>
                  <Link
                    to="skype:live:.cid.313b26920df66baf"
                    className="block px-6 py-2 text-gray-700 font-bold text-base"
                  >

                    <li className="flex items-center gap-3">

                      <FaSkype className="text-xl" />
                      Futuretouch
                    </li>
                  </Link>
                  <Link
                    to="mailto:info@futuretouch.in"
                    className="block px-6 py-2 text-gray-700 font-bold text-base"
                  >
                    {" "}
                    <li className="flex items-center gap-3">
                      <FaEnvelope className="text-xl" /> info@futuretouch.in
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </div>
          <p className="hidden lg:block " >|</p>
          <Link to="https://crm.futuretouch.in/authentication/login" className="hidden lg:block " >Login</Link>
          <p className="hidden lg:block " >|</p>
          <div className="flex gap-2 lg:gap-3">
            <Link to="https://www.facebook.com/Futureittouch" target="blank">  <FaFacebookF className="h-6 w-6 rounded-full bg-white text-[#4243c9] p-1" /></Link>
            <Link to="https://x.com/futureittouch" target="blank"> <FaTwitter className="w-6 h-6 rounded-full bg-white text-[#4243c9] p-1" /></Link>
            <Link to="https://in.linkedin.com/company/future-it-touch" target="blank"> <FaLinkedinIn className="w-6 h-6 rounded-full bg-white text-[#4243c9] p-1" /></Link>
            <Link to="https://www.instagram.com/future_it_touch/" target="blank"> <FaInstagram className="w-6 h-6 rounded-full bg-white text-[#4243c9] p-1" /></Link>
            <Link to="https://www.youtube.com/channel/UCirWettrTWfsFRzdGRIc6BQ/about" target="blank">    <FaYoutube className="w-6 h-6 rounded-full bg-white text-[#4243c9] p-1" /></Link>
            <Link to="https://github.com/Future-IT-Touch-Private-Limited" target="blank">    <FaGithub className="w-6 h-6 rounded-full bg-white text-[#4243c9] p-1" /></Link>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navbar;
