import React, {useEffect} from "react";
import gm from "../Assets/wsj.webp";
import ec from "../Assets/ec.webp";
import d1 from "../Assets/joomla.webp";
import { FaChevronRight, FaCog, FaHeadset, FaJoomla, FaLaptop, FaLaptopCode, FaNetworkWired, FaSignInAlt, FaSwimmer } from "react-icons/fa";
import { Link } from "react-router-dom";

const Shopify = () => {
  useEffect(() => {
    document.title = "WP, Shopify, Joomla  - Future IT Touch Private Limited";
    window.scrollTo(0, 0);
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Future IT Touch Private Limited is a leading IT solutions provider.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "IT, Technology, Solutions, Future IT Touch";
    document.head.appendChild(metaKeywords);

    // Cleanup function to remove the meta tags when the component unmounts
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);
  return (
    <>
       <div className="h-24 max-sm:h-[57px] max-md:h-20 max-lg:h-24 bg-white"></div>
      <div className=" relative overflow-hidden bg-[#442a79] h-[350px] max-lg:h-auto">
      <div className="flex max-sm:block max-lg:block px-28  max-sm:px-5 max-lg:px-10  max-sm:py-7 gap-10  pt-12 max-pt-1  max-lg:px-10  max-lg:py-5">
          <div className="  ">
            <h1 className="text-white font-bold text-4xl  max-lg:text-2xl">
              WORDPRESS AND SHOPIFY DEVELOPMENT
            </h1>

            <div className="text-[#dcd7f8]  mt-3 text-lg text-justify max-lg:text-base">
              <p>
                We are a full-fledged, on-time and on-budget Joomla website
                design company in India. Hire our Joomla developers to build a
                wide variety of websites namely an eCommerce store, a directory
                site, a gallery site or a membership site at quite reasonable
                prices. We design and develop powerful websites with Joomla CMS
                customization. Our Joomla template design, is also considered
                among the best within the industry. Get in touch with us to know
                details about the Joomla development services we offer. .
              </p>
            </div>
            <div className="flex gap-3 mt-5">
              <Link to="/contact" className="flex items-center bg-[#dc3545] justify-center bg-red text-white py-2 px-4 sm:px-8 font-heading transition duration-400 ease-in-out rounded-md text-base font-base focus:outline-none hover:bg-gray-300 hover:bg-gradient-to-r from-teal-400 to-indigo-700">
                Quick Enquiry
              </Link>
              <button className="flex items-center bg-dark-200 justify-center border text-white py-2 px-2  sm:px-8 font-heading ">
              <Link to="tel:+917056937000">

Call us - 7056937000

</Link>
              </button>
            </div>
          </div>
          <img src={gm} alt="" className="w-96 pt-0 max-lg:pt-10 max-lg:mx-auto " />
        </div>
      </div>

      <div className="flex max-lg:block gap-10  px-32  max-sm:px-5 max-lg:px-10 py-14">
        <div className="w-2/3 max-lg:w-full pt-5">
          <h4 className="text-4xl max-sm:text-2xl font-bold mb-3 text-[#050748] ">
            Joomla Web Development Company
          </h4>

          <div className="text-lg text-justify mt-5 text-[#6a6a8e] ">
            <p>
              Being a reputable Joomla web development company in India, we have
              been serving clients across various business verticals. Using the
              latest version of this amazing CMS and its user friendly features
              & extensions, we have created a loyal chain of satisfied
              clientele. Our experienced Joomla developers have thorough
              technical knowledge and expertise in developing functional
              websites to meet the specific business requirements of our
              clients. We also provide customization services of existing Joomla
              website, Joomla version upgrade and post deployment maintenance &
              support. Avail our Joomla web development services and allow us to
              provide you with an enhanced Joomla experience.
            </p>
          </div>
        </div>

        <div className=" w-1/3 max-lg:w-full">
          <img src={d1} alt="Design 1" className="w-96  max-sm:mb-3  max-lg:mx-auto" />
        </div>
      </div>

      <div className="bg-image-third mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row px-40  max-sm:px-5 max-lg:px-10">
          <div className="md:w-1/2"></div>

          <div className="md:w-1/2 md:ml-4">
            <div className="">
              <h3 className="text-3xl font-bold mb-4 text-[#050748]">
                WHAT CAN WE DO FOR YOU?
              </h3>
              <p className="mb-4">
                We would be happy to discuss the project with you in person
              </p>
              <div className="flex items-center mb-4">
                <span className="mr-2">Call us on :</span>
                <p className="text-lg">+91 7056937000</p>
              </div>
              <div>
                <Link to="/our-portfolio">
                  <button className="bg-[#00a2ff] hover:bg-[#3b61e8] text-white font-semibold py-2 px-4 rounded-md mr-4">
                    RECENT PROJECTS
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#f2f2f2] px-32  max-sm:px-5 max-lg:px-10 py-14">
        <h3 className="text-[27px] text-[#050748] font-bold">
        Our Joomla Website Development Services
        </h3>
        <p className=" text-lg text-[#6a6a8e] mt-5">
        As a highly experienced Joomla website development company in India, we provide every possible service with this CMS platform. Check out the list of our services:
        </p>
        <div className=" grid grid-cols-1 md:grid-cols-3 pt-10 gap-10  text-lg text-[#e33f70]">
          <div className="flex items-start gap-5">
            <FaLaptop className="text-4xl" />
            <p className="text-xl ">Joomla Website Development</p>
          </div>
          <div className="flex items-start gap-5">
            <FaNetworkWired className="text-4xl" />
            <p className="text-xl ">Joomla Theme Customization</p>
          </div>
          <div className="flex items-start gap-5">
            <FaJoomla className="text-4xl" />
            <p className="text-xl ">Custom Modules Development</p>
          </div>
          <div className="flex items-start gap-5">
            <FaLaptopCode className="text-4xl" />
            <p className="text-xl ">Joomla Application Development</p>
          </div>
          <div className="flex items-start gap-5">
            <FaSwimmer className="text-4xl" />
            <p className="text-xl ">Joomla Extension Integration</p>
          </div>
          <div className="flex items-start gap-5">
            <FaHeadset className="text-4xl" />
            <p className="text-xl ">Joomla Website Maintenance</p>
          </div>
          <div className="flex items-start gap-5">
            <FaCog className="text-4xl" />
            <p className="text-xl ">Joomla Customization Services</p>
          </div>
          <div className="flex items-start gap-5">
            <FaSignInAlt className="text-4xl" />
            <p className="text-xl ">Joomla Migration Services</p>
          </div>
        </div>
      </div>

      <div className="bg-image-second  mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-5">
          <div className="md:w-1/2 max-sm:hidden"></div>

          <div className="md:w-1/2 md:ml-4">
            <div class="flex gap-5 items-center ">
              <div class="text-white  ">
                <h5 className="text-lg font-semibold"> GET SUPPORT FOR</h5>
                <h3 className="text-5xl max-sm:text-3xl max-md:text-3xl  font-bold">
                  90 DAYS
                </h3>
              </div>

              <div>
                <Link to="/our-portfolio">
                  <button className="bg-[#00a2ff] hover:bg-[#3b61e8] text-white text-nowrap font-semibold py-4  px-6 max-sm:px-3 rounded-md mr-4">
                    REQUEST A QUOTE
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 max-lg:block  gap-10  bg-[#f2f2f2]  px-32  max-sm:px-5 max-lg:px-10 py-14">
        <div className="col-span-7">
          <h4 className="text-3xl font-semibold mb-3 text-[#050748] ">
            Why Choose Us?
          </h4>
          <h4 className="text-4xl font-bold mb-3 text-[#050748] ">
            From Our Blog
          </h4>
          <p className="mb-6 text-[#f00] text-xl mt-6">
            Why Custom CMS Website Design Is Important to Your Business
          </p>
          <div className="text-xl text-justify text-[#6a6a8e] ">
            <p>
              Nowadays, almost every business has experienced that it can
              succeed when its offerings are visible to their customers. For
              that reason, business owners invest in spending money on
              developing visually attractive websites and look for sophisticated
              SEO experts to differentiate themselves amidst the clutter.
            </p>
          </div>
        </div>

        <div className="col-span-5 mt-10">
          <img src={ec} alt="" className="border-[18px] border-[#dfdfdf]" />
        </div>
      </div>

      <div className="bg-custome py-10 px-4 sm:px-4 md:px-10 lg:px-20 grid grid-cols-1 md:grid-cols-12 gap-4">
        <div className="md:col-span-6">
          <h1 className="text-[#050748] text-3xl font-bold">
            Get New Insights Weekly
          </h1>
          <p className="mt-4">
            Your Personal data is secure as per our policy we dont share your
            personal information, <br /> Freely Subscribe !
          </p>
        </div>
        <div className="new-component relative md:col-span-6 w-full sm:w-full md:w-full lg:w-11/12 left-0 sm:left-0 md:left-1 lg:left-10 top-5">
          <form className="flex items-center justify-center">
            <input
              type="email"
              placeholder="Email Your Address"
              name="emails"
              className="w-full h-16 px-8 text-base text-gray-700 placeholder-gray-500 bg-white border border-gray-300 rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
            <button
              type="submit"
              name="submit"
              className="inline-flex items-center absolute top-0 right-0 mr-1 mt-1 px-4 py-2 text-base font-medium rounded-full text-white bg-gradient-to-r from-[#ffbd84] to-[#ff1f8e] shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              style={{
                borderRadius: "28px 100px 100px 28px",
                lineHeight: "40px",
              }}
            >
              Subscribe <FaChevronRight className="ml-2" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Shopify;
