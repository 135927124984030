import React, {useEffect} from "react";
import gm from "../Assets/Haskell.webp";
import ec from "../Assets/ec.webp";
import d1 from "../Assets/Haskellwhy.webp";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";


const Haskell = () => {
  useEffect(() => {
    document.title = "Haskell AI/ML Development  - Future IT Touch Private Limited";
    window.scrollTo(0, 0);
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Future IT Touch Private Limited is a leading IT solutions provider.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "IT, Technology, Solutions, Future IT Touch";
    document.head.appendChild(metaKeywords);

    // Cleanup function to remove the meta tags when the component unmounts
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);
  return (
    <>
       <div className="h-24 max-sm:h-[57px] max-md:h-20 max-lg:h-24 bg-white"></div>
      <div className=" relative overflow-hidden bg-[#442a79] h-[350px] max-lg:h-auto">
      <div className="flex max-sm:block max-lg:block px-28  max-sm:px-5 max-lg:px-10  max-sm:py-7 gap-10  pt-12 max-pt-1  max-lg:px-10  max-lg:py-5">
          <div className="  ">
            <h1 className="text-white font-bold text-4xl  max-sm:text-2xl">
            Haskell AI/ML Development
                            </h1>

            <div className="text-[#dcd7f8]  mt-3 text-lg text-justify ">
              <p>
              AI and machine learning are huge topics in technology. In this series, we'll explore how Haskell's unique features as a language can be valuable in crafting better AI programs. In particular, we'll explore some advanced concepts in type safety, and apply these to the machine learning framework Tensor Flow. Haskell has strong abstraction capabilities and built-in memory management. Its code is highly reusable and easy to understand. This language is favored by many developers because it increases productivity.

              </p>
            </div>
            <div className="flex gap-3 mt-5">
              <Link to="/contact" className="flex items-center bg-[#dc3545] justify-center bg-red text-white py-2 px-4 sm:px-8 font-heading transition duration-400 ease-in-out rounded-md text-base font-base focus:outline-none hover:bg-gray-300 hover:bg-gradient-to-r from-teal-400 to-indigo-700">
                Quick Enquiry
              </Link>
              <button className="flex items-center bg-dark-200 justify-center border text-white py-2 px-2  sm:px-8 font-heading ">
              <Link to="tel:+917056937000">

Call us - 7056937000

</Link>
              </button>
            </div>
          </div>
          <img src={gm} alt="" className="w-80 h-72 pt-0 max-lg:pt-10 max-lg:mx-auto" />
        </div>
      </div>

      <div className="flex max-lg:block gap-10  px-32  max-sm:px-5 max-lg:px-10 max-lg:px-10 py-14">
        <div className="w-2/3 max-lg:w-full pt-5">
          <h4 className="text-4xl font-bold mb-3 text-[#050748] ">
          Why Haskell AI/ML Solution
          </h4>

          <div className="text-lg text-justify mt-5 text-[#6a6a8e] ">
            <p>
            Haskell’s learning curve, however, is fairly steep when compared to other languages commonly used for AI development. Haskell is a lazy language, which means that values are evaluated only when needed. This often leads to difficulties in memory allocation.
            </p>
            <p>
            One of the most popular Haskell libraries for machine learning is HLearn. The library exploits the algebraic structures inherent in learning systems and contains several useful templates for implementation. Haskell also has a TensorFlow binding which can be used for deep learning.
            </p>
     
          </div>
        </div>

        <div className=" w-1/3 max-lg:w-full">
          <img src={d1} alt="Design 1" className="w-96 mt-5 h-60  max-sm:mb-3 max-lg:mx-auto" />
        </div>
      </div>

      <div className="bg-image-third mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row px-40  max-sm:px-5 max-lg:px-10">
          <div className="md:w-1/2"></div>

          <div className="md:w-1/2 md:ml-4">
            <div className="">
              <h3 className="text-3xl font-bold mb-4 text-[#050748]">
                WHAT CAN WE DO FOR YOU?
              </h3>
              <p className="mb-4">
                We would be happy to discuss the project with you in person
              </p>
              <div className="flex items-center mb-4">
                <span className="mr-2">Call us on :</span>
                <p className="text-lg">+91 7056937000</p>
              </div>
              <div>
                <Link to="/our-portfolio">
                  <button className="bg-[#00a2ff] hover:bg-[#3b61e8] text-white font-semibold py-2 px-4 rounded-md mr-4">
                    RECENT PROJECTS
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#f2f2f2] px-32  max-sm:px-5 max-lg:px-10 max-lg:px-10 py-14">
        <h3 className="text-[27px] text-[#050748] font-bold">
        Great at Abstraction and Highly Expressive
        </h3>
        
            <p className="text-xl text-justify text-[#6a6a8e] my-5">
            Haskell is perfect for abstract mathematics and creates highly expressive algorithms while maintaining great performance.

Considering its key features, Haskell is good for projects that involve the creation of extensive neural networks. In spite of its obvious benefits though, it does not have the same level of support as other programming languages, making it hard to get support. However, it is notable that Facebook uses a Haskell ML model to fight spamming.
            </p>
       
      
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <p className="text-[#6a6a8e] text-lg flex  gap-3">
                <FaChevronRight className="text-[#df2466] mt-2 text-sm" />
                On the other hand Haskell is an strictly typed programming language. In short we can say it is an functional programming language.
              </p>
              <p className="text-[#6a6a8e] text-lg flex  gap-3">
                <FaChevronRight className="text-[#df2466] mt-2 text-sm" />
                Haskell is strictly typed language like java, c, c++ so it is very easy to check the error at compile time only.
              </p>
              <p className="text-[#6a6a8e] text-lg flex  gap-3">
                <FaChevronRight className="text-[#df2466] mt-2 text-sm" />
                Haskell based on the lambda calculus, also every function we written in Haskell is a mathematical function, which leads to less error.
                                        
              </p>
              <p className="text-[#6a6a8e] text-lg flex  gap-3">
                <FaChevronRight className="text-[#df2466] mt-2 text-sm" />
                On the other hand Haskell is used by Wagon, thought-out and DoxIQ.
                                        
              </p>
              <p className="text-[#6a6a8e] text-lg flex  gap-3">
                <FaChevronRight className="text-[#df2466] mt-2 text-sm" />
                Haskell stable release was come in year 2010 designed by Paul Hudak.

              </p>
              <p className="text-[#6a6a8e] text-lg flex  gap-3">
                <FaChevronRight className="text-[#df2466] mt-2 text-sm" />
                Functional programming language are more and more concurrent, hence provide better performance as well.

                                        
              </p>

            </div>
      </div>

      <div className="bg-image-second  mx-auto px-4 py-8">
        <div className="flex  flex-col md:flex-row gap-5">
          <div className="md:w-1/2 max-sm:hidden"></div>

          <div className="md:w-1/2 md:ml-4">
            <div class="flex gap-5 items-center ">
              <div class="text-white  ">
                <h5 className="text-lg font-semibold"> GET SUPPORT FOR</h5>
                <h3 className="text-5xl max-sm:text-3xl max-md:text-3xl  font-bold">
                  90 DAYS
                </h3>
              </div>

              <div>
                <Link to="/our-portfolio">
                  <button className="bg-[#00a2ff] hover:bg-[#3b61e8] text-white text-nowrap font-semibold py-4  px-6 max-sm:px-3 rounded-md mr-4">
                    REQUEST A QUOTE
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="grid grid-cols-12 max-lg:block gap-10  bg-[#f2f2f2]  px-32  max-sm:px-5 max-lg:px-10 py-14">
        <div className="col-span-7">
        
          <h4 className="text-4xl font-bold my-5 text-[#050748] ">
            From Our Blog
          </h4>
          
          <div className="text-xl text-justify text-[#6a6a8e] ">
            <p className="text-[#e33f70] mb-4">Why Haskell AI/ML Is Important to Your Business</p>
            <p>
              Nowadays, almost every business has experienced that it can
              succeed when its offerings are visible to their customers. For
              that reason, business owners invest in spending money on
              developing visually attractive websites and look for sophisticated
              SEO experts to differentiate themselves amidst the clutter.
            </p>
          </div>
        </div>

        <div className="col-span-5">
          <img src={ec} alt="" className="border-[18px] border-[#dfdfdf] max-lg:mt-10" />
        </div>
      </div>

      <div className="bg-custome py-10 px-4 sm:px-4 md:px-10 lg:px-20 grid grid-cols-1 md:grid-cols-12 gap-4">
        <div className="md:col-span-6">
          <h1 className="text-[#050748] text-3xl font-bold">
            Get New Insights Weekly
          </h1>
          <p className="mt-4">
            Your Personal data is secure as per our policy we dont share your
            personal information, <br /> Freely Subscribe !
          </p>
        </div>
        <div className="new-component relative md:col-span-6 w-full sm:w-full md:w-full lg:w-11/12 left-0 sm:left-0 md:left-1 lg:left-10 top-5">
          <form className="flex items-center justify-center">
            <input
              type="email"
              placeholder="Email Your Address"
              name="emails"
              className="w-full h-16 px-8 text-base text-gray-700 placeholder-gray-500 bg-white border border-gray-300 rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
            <button
              type="submit"
              name="submit"
              className="inline-flex items-center absolute top-0 right-0 mr-1 mt-1 px-4 py-2 text-base font-medium rounded-full text-white bg-gradient-to-r from-[#ffbd84] to-[#ff1f8e] shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              style={{
                borderRadius: "28px 100px 100px 28px",
                lineHeight: "40px",
              }}
            >
              Subscribe <FaChevronRight className="ml-2" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Haskell;
